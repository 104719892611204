/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import {
  AlertVehicleMapWaypointFragmentDoc,
  AlertVehicleMapVehicleFragmentDoc,
} from '../../../../ad-operator-modals/src/lib/AlertVehicleMap/AlertVehicleMap.hook'
import { PassengerUnbuckledActionCardVehicleFragmentDoc } from '../../../../ad-operator-modals/src/lib/PassengerUnbuckledModal/PassengerUnbuckledModal.hook'
import { ServiceCancellationActionCardVehicleFragmentDoc } from '../../../../ad-operator-modals/src/lib/ServiceCancellationModal/serviceCancellationModal.hook'
import { AdVehicleDoorStatusFragmentDoc } from '../../../../ad-operator-modals/src/lib/components/AdVehicleDoorStatus/AdVehicleDoorStatus.hook'
import { EmergencyStopActionCardVehicleFragmentDoc } from '../../../../ad-operator-modals/src/lib/components/EmergencyStopActionCard/EmergencyStopActionCard.hook'
import { EvaluateCabinSafetyCheckStatusFragmentDoc } from '../../../../ad-operator-modals/src/lib/utils/evaluateCabinSafetyCheckStatus/evaluateCabinSafetyCheckStatus.hook'
import { AdOnBoardPassengersVehicleFragmentDoc } from '../../../../ad-passenger-list/src/lib/AdOnBoardPassengers/AdOnBoardPassengers.hook'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'
import { AdAlertListVehicleFragmentDoc } from '../AdAlertList/AdAlertList.hook'
import { AdVehicleModalHeaderVehicleFragmentDoc } from './AdVehicleModalHeader/AdVehicleModalHeader.hook'

const defaultOptions = {} as const
export type AdVehicleModalQueryVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type AdVehicleModalQuery = {
  __typename?: 'Query'
  vehicleByID: {
    __typename?: 'Vehicle'
    id: string
    isWheelchairAccessible: boolean | null
    isSelfDriving: boolean
    label: number | null
    fccUpcomingWaypoints: Array<{
      __typename?: 'FccWaypoint'
      id: string
      adState: Types.FccWaypointState | null
      stop: {
        __typename?: 'Stop'
        routingLocation: {
          __typename?: 'Location'
          latitude: number
          longitude: number
        }
      } | null
      actions: Array<
        | {
            __typename: 'FccWaypointDeliveryAction'
            estimatedAt: string
            id: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename: 'FccWaypointHubReturnAction'; estimatedAt: string }
        | {
            __typename: 'FccWaypointPickupAction'
            estimatedAt: string
            adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
            id: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename: 'FccWaypointReallocationAction'; estimatedAt: string }
      >
    }> | null
    fccOperatorAdAlerts: {
      __typename?: 'FccAdAlerts'
      alerts: Array<{
        __typename: 'FccAdAlert'
        id: string
        createdAt: string
        type: Types.FccAdAlertType
        status: Types.FccAdAlertStatus
      }>
    } | null
    latestLocation: {
      __typename?: 'VehicleLocation'
      heading: number | null
      location: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      } | null
    } | null
    latestADTelemetry: {
      __typename?: 'ADTelemetry'
      rightSlidingDoorOpen: {
        __typename?: 'ADTelemetryBooleanWithTimestamp'
        value: boolean
        timestamp: string
      } | null
      speed: {
        __typename?: 'ADTelemetryFloatWithTimestamp'
        value: number
      } | null
      centralLockLocked: {
        __typename?: 'ADTelemetryBooleanWithTimestamp'
        value: boolean
      } | null
    } | null
    pooling: { __typename?: 'Pooling'; enabled: boolean } | null
    adSeatsLayout: {
      __typename?: 'FccSeatLayout'
      thirdRowRight: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
      thirdRowMiddle: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
      thirdRowLeft: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
      secondRowRight: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
      secondRowMiddle: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
      secondRowLeft: {
        __typename?: 'FccSeat'
        occupancy: Types.FccSeatOccupancy
        seatbelt: Types.FccSeatbelt
      }
    } | null
    fccCurrentAdAlerts: {
      __typename?: 'FccAdAlerts'
      alerts: Array<{
        __typename?: 'FccAdAlert'
        id: string
        type: Types.FccAdAlertType
      }>
    } | null
  }
}

export type StopHandlingVehicleMutationVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type StopHandlingVehicleMutation = {
  __typename?: 'Mutation'
  fccStopHandlingVehicle: any | null
}

export const AdVehicleModalDocument = gql`
  query AdVehicleModal($vehicleId: ID!) {
    vehicleByID(id: $vehicleId) {
      id
      fccUpcomingWaypoints {
        ...AlertVehicleMapWaypoint
        ...EvaluateCabinSafetyCheckStatus
      }
      ...AdVehicleModalHeaderVehicle
      ...AdAlertListVehicle
      ...AlertVehicleMapVehicle
      ...AdOnBoardPassengersVehicle
      ...AdVehicleDoorStatus
      ...PassengerUnbuckledActionCardVehicle
      ...ServiceCancellationActionCardVehicle
      ...EmergencyStopActionCardVehicle
    }
  }
  ${AlertVehicleMapWaypointFragmentDoc}
  ${EvaluateCabinSafetyCheckStatusFragmentDoc}
  ${AdVehicleModalHeaderVehicleFragmentDoc}
  ${AdAlertListVehicleFragmentDoc}
  ${AlertVehicleMapVehicleFragmentDoc}
  ${AdOnBoardPassengersVehicleFragmentDoc}
  ${AdVehicleDoorStatusFragmentDoc}
  ${PassengerUnbuckledActionCardVehicleFragmentDoc}
  ${ServiceCancellationActionCardVehicleFragmentDoc}
  ${EmergencyStopActionCardVehicleFragmentDoc}
`

/**
 * __useAdVehicleModalQuery__
 *
 * To run a query within a React component, call `useAdVehicleModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdVehicleModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdVehicleModalQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAdVehicleModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdVehicleModalQuery,
    AdVehicleModalQueryVariables
  > &
    (
      | { variables: AdVehicleModalQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AdVehicleModalQuery, AdVehicleModalQueryVariables>(
    AdVehicleModalDocument,
    options,
  )
}
export function useAdVehicleModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdVehicleModalQuery,
    AdVehicleModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AdVehicleModalQuery, AdVehicleModalQueryVariables>(
    AdVehicleModalDocument,
    options,
  )
}
export function useAdVehicleModalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdVehicleModalQuery,
        AdVehicleModalQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AdVehicleModalQuery,
    AdVehicleModalQueryVariables
  >(AdVehicleModalDocument, options)
}
export type AdVehicleModalQueryHookResult = ReturnType<
  typeof useAdVehicleModalQuery
>
export type AdVehicleModalLazyQueryHookResult = ReturnType<
  typeof useAdVehicleModalLazyQuery
>
export type AdVehicleModalSuspenseQueryHookResult = ReturnType<
  typeof useAdVehicleModalSuspenseQuery
>
export type AdVehicleModalQueryResult = Apollo.QueryResult<
  AdVehicleModalQuery,
  AdVehicleModalQueryVariables
>
export const StopHandlingVehicleDocument = gql`
  mutation stopHandlingVehicle($vehicleId: ID!) {
    fccStopHandlingVehicle(vehicleId: $vehicleId)
  }
`
export type StopHandlingVehicleMutationFn = Apollo.MutationFunction<
  StopHandlingVehicleMutation,
  StopHandlingVehicleMutationVariables
>

/**
 * __useStopHandlingVehicleMutation__
 *
 * To run a mutation, you first call `useStopHandlingVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopHandlingVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopHandlingVehicleMutation, { data, loading, error }] = useStopHandlingVehicleMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useStopHandlingVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StopHandlingVehicleMutation,
    StopHandlingVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    StopHandlingVehicleMutation,
    StopHandlingVehicleMutationVariables
  >(StopHandlingVehicleDocument, options)
}
export type StopHandlingVehicleMutationHookResult = ReturnType<
  typeof useStopHandlingVehicleMutation
>
export type StopHandlingVehicleMutationResult =
  Apollo.MutationResult<StopHandlingVehicleMutation>
export type StopHandlingVehicleMutationOptions = Apollo.BaseMutationOptions<
  StopHandlingVehicleMutation,
  StopHandlingVehicleMutationVariables
>
