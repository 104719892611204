/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type RioVehiclesQueryVariables = Types.Exact<{
  serviceAreaUUID: Types.Scalars['ID']['input']
  search: Types.Scalars['String']['input']
  fleets?: Types.InputMaybe<
    Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']
  >
}>

export type RioVehiclesQuery = {
  __typename?: 'Query'
  fleetVehicles: {
    __typename?: 'VehiclesResponse'
    vehicles: Array<{
      __typename?: 'Vehicle'
      vin: string | null
      mileage: number | null
      id: string
      label: number | null
      isWheelchairAccessible: boolean | null
      isSelfDriving: boolean
      serviceAreaUUID: string
      state: Types.VehicleState
      fleet: { __typename?: 'Fleet'; id: string; name: string } | null
    }>
  }
}

export const RioVehiclesDocument = gql`
  query RioVehicles($serviceAreaUUID: ID!, $search: String!, $fleets: [ID!]) {
    fleetVehicles(
      input: {
        serviceAreaUUID: $serviceAreaUUID
        search: $search
        fleets: $fleets
      }
    ) {
      vehicles {
        vin
        mileage
        id
        label
        isWheelchairAccessible
        isSelfDriving
        serviceAreaUUID
        state
        fleet {
          id
          name
        }
      }
    }
  }
`

/**
 * __useRioVehiclesQuery__
 *
 * To run a query within a React component, call `useRioVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRioVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRioVehiclesQuery({
 *   variables: {
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *      search: // value for 'search'
 *      fleets: // value for 'fleets'
 *   },
 * });
 */
export function useRioVehiclesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RioVehiclesQuery,
    RioVehiclesQueryVariables
  > &
    (
      | { variables: RioVehiclesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RioVehiclesQuery, RioVehiclesQueryVariables>(
    RioVehiclesDocument,
    options,
  )
}
export function useRioVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RioVehiclesQuery,
    RioVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RioVehiclesQuery, RioVehiclesQueryVariables>(
    RioVehiclesDocument,
    options,
  )
}
export function useRioVehiclesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RioVehiclesQuery,
        RioVehiclesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<RioVehiclesQuery, RioVehiclesQueryVariables>(
    RioVehiclesDocument,
    options,
  )
}
export type RioVehiclesQueryHookResult = ReturnType<typeof useRioVehiclesQuery>
export type RioVehiclesLazyQueryHookResult = ReturnType<
  typeof useRioVehiclesLazyQuery
>
export type RioVehiclesSuspenseQueryHookResult = ReturnType<
  typeof useRioVehiclesSuspenseQuery
>
export type RioVehiclesQueryResult = Apollo.QueryResult<
  RioVehiclesQuery,
  RioVehiclesQueryVariables
>
