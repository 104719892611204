/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type SetTaskNotificationPendingMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
  until?: Types.InputMaybe<Types.Scalars['DateTime']['input']>
}>

export type SetTaskNotificationPendingMutation = {
  __typename?: 'Mutation'
  setTaskNotificationPending:
    | {
        __typename?: 'HubServiceNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'IvcTaskNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'PassengerUnbuckled'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'RoadBlockageTaskNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'ShiftDeviationNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'StopAccessibilityDownvotedByDrivers'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'StopActivationFailedNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'StopAreaMissingActiveStopsNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'StopDownVotedByDrivers'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'StopPotentiallyUnreachable'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'StopValidationFailed'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'TimeManagementNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'TripPrebookingNoAssignment'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'VehicleAvailabilityNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'VehicleIncidentNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'VehicleSurveillanceNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'VehicleWaypointAttendance'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          initiatedBySystem: boolean
          to: Types.TaskNotificationStatus
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
}

export const SetTaskNotificationPendingDocument = gql`
  mutation setTaskNotificationPending($id: ID!, $until: DateTime) {
    setTaskNotificationPending(id: $id, until: $until) {
      id
      status
      assigned {
        timestamp
        backofficeUserId
        backofficeUser {
          username
        }
        to
        initiatedBySystem
      }
      resolved {
        timestamp
        backofficeUserId
        backofficeUser {
          username
        }
        initiatedBySystem
        to
        initiatedBySystem
      }
    }
  }
`
export type SetTaskNotificationPendingMutationFn = Apollo.MutationFunction<
  SetTaskNotificationPendingMutation,
  SetTaskNotificationPendingMutationVariables
>

/**
 * __useSetTaskNotificationPendingMutation__
 *
 * To run a mutation, you first call `useSetTaskNotificationPendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTaskNotificationPendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTaskNotificationPendingMutation, { data, loading, error }] = useSetTaskNotificationPendingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useSetTaskNotificationPendingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetTaskNotificationPendingMutation,
    SetTaskNotificationPendingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetTaskNotificationPendingMutation,
    SetTaskNotificationPendingMutationVariables
  >(SetTaskNotificationPendingDocument, options)
}
export type SetTaskNotificationPendingMutationHookResult = ReturnType<
  typeof useSetTaskNotificationPendingMutation
>
export type SetTaskNotificationPendingMutationResult =
  Apollo.MutationResult<SetTaskNotificationPendingMutation>
export type SetTaskNotificationPendingMutationOptions =
  Apollo.BaseMutationOptions<
    SetTaskNotificationPendingMutation,
    SetTaskNotificationPendingMutationVariables
  >
