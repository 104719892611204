/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type LogoutOperatorMutationVariables = Types.Exact<{
  serviceAreaUUID: Types.Scalars['ID']['input']
}>

export type LogoutOperatorMutation = {
  __typename?: 'Mutation'
  fccSetOperatorAvailability: {
    __typename?: 'FccOperatorState'
    availability: Types.FccOperatorAvailability
  }
}

export type LoginOperatorMutationVariables = Types.Exact<{
  serviceAreaUUID: Types.Scalars['ID']['input']
}>

export type LoginOperatorMutation = {
  __typename?: 'Mutation'
  fccSetOperatorAvailability: {
    __typename?: 'FccOperatorState'
    availability: Types.FccOperatorAvailability
  }
}

export const LogoutOperatorDocument = gql`
  mutation logoutOperator($serviceAreaUUID: ID!) {
    fccSetOperatorAvailability(
      input: { availability: OFFLINE, serviceAreaUUID: $serviceAreaUUID }
    ) {
      availability
    }
  }
`
export type LogoutOperatorMutationFn = Apollo.MutationFunction<
  LogoutOperatorMutation,
  LogoutOperatorMutationVariables
>

/**
 * __useLogoutOperatorMutation__
 *
 * To run a mutation, you first call `useLogoutOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutOperatorMutation, { data, loading, error }] = useLogoutOperatorMutation({
 *   variables: {
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *   },
 * });
 */
export function useLogoutOperatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutOperatorMutation,
    LogoutOperatorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LogoutOperatorMutation,
    LogoutOperatorMutationVariables
  >(LogoutOperatorDocument, options)
}
export type LogoutOperatorMutationHookResult = ReturnType<
  typeof useLogoutOperatorMutation
>
export type LogoutOperatorMutationResult =
  Apollo.MutationResult<LogoutOperatorMutation>
export type LogoutOperatorMutationOptions = Apollo.BaseMutationOptions<
  LogoutOperatorMutation,
  LogoutOperatorMutationVariables
>
export const LoginOperatorDocument = gql`
  mutation loginOperator($serviceAreaUUID: ID!) {
    fccSetOperatorAvailability(
      input: { availability: AVAILABLE, serviceAreaUUID: $serviceAreaUUID }
    ) {
      availability
    }
  }
`
export type LoginOperatorMutationFn = Apollo.MutationFunction<
  LoginOperatorMutation,
  LoginOperatorMutationVariables
>

/**
 * __useLoginOperatorMutation__
 *
 * To run a mutation, you first call `useLoginOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginOperatorMutation, { data, loading, error }] = useLoginOperatorMutation({
 *   variables: {
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *   },
 * });
 */
export function useLoginOperatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginOperatorMutation,
    LoginOperatorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LoginOperatorMutation,
    LoginOperatorMutationVariables
  >(LoginOperatorDocument, options)
}
export type LoginOperatorMutationHookResult = ReturnType<
  typeof useLoginOperatorMutation
>
export type LoginOperatorMutationResult =
  Apollo.MutationResult<LoginOperatorMutation>
export type LoginOperatorMutationOptions = Apollo.BaseMutationOptions<
  LoginOperatorMutation,
  LoginOperatorMutationVariables
>
