/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
/* eslint-disable */

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AreaDetails: [
      'CooperationDetails',
      'HubDetails',
      'InFieldBreakDetails',
      'ShortBreakDetails',
    ],
    CursorListResponse: [
      'DamageReportList',
      'FccAuditLogList',
      'VehicleChangeList',
      'VehicleCheckList',
    ],
    FccDoorActuationStatus: [
      'FccDoorActuationNotPossibleStatus',
      'FccDoorActuationPossibleStatus',
      'FccDoorActuationRequestFailureStatus',
      'FccDoorActuationRequestSuccessStatus',
    ],
    FccUpdateAdPoolingStatusStatus: [
      'FccUpdateAdPoolingStatusFailure',
      'FccUpdateAdPoolingStatusSuccess',
    ],
    FccWaypointAction: [
      'FccWaypointDeliveryAction',
      'FccWaypointHubReturnAction',
      'FccWaypointPickupAction',
      'FccWaypointReallocationAction',
    ],
    FleetEntityChange: [
      'BoxPcReplacedChange',
      'BoxPcWipedChange',
      'CameraReplacedChange',
      'CdisBoxReplacedChange',
      'KeyRfidReplacedChange',
      'RouterReplacedChange',
      'TabletPhoneNumberReplacedChange',
      'TabletReplacedChange',
      'VehicleCreatedChange',
      'VehicleServiceAbortedChange',
      'VehicleStateUpdatedChange',
      'VehicleUpdatedChange',
    ],
    FleetProcess: [
      'MainInspectionProcess',
      'MileageBasedInspectionProcess',
      'VICProcess',
    ],
    Geometry: ['MultiPolygon', 'Shape'],
    IBackofficeUserIdentity: ['BackofficeUser', 'BackofficeUserIdentity'],
    ListResponse: [
      'CustomPriceConfigsResponse',
      'DefaultPriceConfigsResponse',
      'DsBossScenariosResponse',
      'HwMgmtVehicleConfigurationsResponse',
      'TaskNotificationList',
      'VehicleHubArrivalsResponse',
      'VehiclesByFleetStateResponse',
    ],
    PaymentMethod: ['ApplePay', 'CreditCard', 'GooglePay', 'PayPal'],
    RioComponent: [
      'RioComponentCheckbox',
      'RioComponentDate',
      'RioComponentDisruptionType',
      'RioComponentOptions',
      'RioComponentPassFail',
      'RioComponentSeverity',
      'RioComponentSimple',
      'RioComponentStaticValue',
      'RioComponentStatus',
      'RioComponentTextArea',
      'RioComponentTroublemaker',
    ],
    SearchResultItemV3: [
      'SearchResultAreaItemV3',
      'SearchResultRoadBlockageItemV3',
      'SearchResultStopItemV3',
      'SearchResultTurnRestrictionItemV3',
    ],
    ShiftStateLogRecord: [
      'AreaLogRecord',
      'PoolingStateChangedLogRecord',
      'SendVehicleToHubLogRecord',
      'ShiftChangedLogRecord',
      'ShiftStateBreakLogRecord',
      'ShiftStateHubLogRecord',
      'ShiftStateShortBreakLogRecord',
      'ShiftStateVehicleLogRecord',
    ],
    TaskNotification: [
      'HubServiceNotification',
      'IvcTaskNotification',
      'PassengerAuthentificationFallbackRequestedNotification',
      'PassengerUnbuckled',
      'RoadBlockageTaskNotification',
      'ShiftDeviationNotification',
      'StopAccessibilityDownvotedByDrivers',
      'StopActivationFailedNotification',
      'StopAreaMissingActiveStopsNotification',
      'StopDownVotedByDrivers',
      'StopPotentiallyUnreachable',
      'StopValidationFailed',
      'TimeManagementNotification',
      'TripPrebookingNoAssignment',
      'VehicleAvailabilityNotification',
      'VehicleIncidentNotification',
      'VehicleSurveillanceNotification',
      'VehicleWaypointAttendance',
    ],
    VehicleChangeFields: [
      'BoxPcReplacedChangeFields',
      'BoxPcWipedChangeFields',
      'CameraReplacedChangeFields',
      'CdisBoxReplacedChangeFields',
      'KeyRfidReplacedChangeFields',
      'RouterReplacedChangeFields',
      'TabletPhoneNumberReplacedChangeFields',
      'TabletReplacedChangeFields',
      'VehicleCreatedChangeFields',
      'VehicleServiceAbortedChangeFields',
      'VehicleStateUpdatedChangeFields',
      'VehicleUpdatedChangeFields',
    ],
  },
}
export default result
