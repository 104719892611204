// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { PassengerAppAreaRoutes } from '@backoffice-frontend/passenger-app-api'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { PassengerAppAreaId } from './PassengerAppAreaId'
import { PassengerAppAreaNavigation } from './PassengerAppAreaNavigation'

const LazyPassengerAppComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./PassengerAppArea')),
)

const PassengerAppComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyPassengerAppComponent />
  </SuspenseLoadingWrapper>
)

export const PassengerAppArea = {
  id: PassengerAppAreaId,
  requiredRight: BoumRight.AREA_TEST,
  NavigationComponent: PassengerAppAreaNavigation,
  route: {
    path: PassengerAppAreaRoutes.route,
    component: PassengerAppComponent,
  },
}
