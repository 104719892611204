/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import { GetPassengersWaypointFragmentDoc } from '../../../../../ad-passenger-list/src/lib/utils/passenger-filters.hook'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'
import { EmergencyStopResolutionVehicleFragmentDoc } from '../../EmergencyStopModal/EmergencyStopResolution/emergencyStopResolution.hook'
import { CabinSafetyCheckVehicleFragmentDoc } from '../CabinSafetyCheck/CabinSafetyCheck.hook'
import { DepartureVehicleFragmentDoc } from '../Departure/Departure.hook'

export type EmergencyStopActionCardVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  latestADTelemetry: {
    __typename?: 'ADTelemetry'
    speed: {
      __typename?: 'ADTelemetryFloatWithTimestamp'
      value: number
    } | null
    rightSlidingDoorOpen: {
      __typename?: 'ADTelemetryBooleanWithTimestamp'
      value: boolean
    } | null
    centralLockLocked: {
      __typename?: 'ADTelemetryBooleanWithTimestamp'
      value: boolean
    } | null
  } | null
  fccUpcomingWaypoints: Array<{
    __typename?: 'FccWaypoint'
    actions: Array<
      | {
          __typename: 'FccWaypointDeliveryAction'
          id: string
          estimatedAt: string
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename?: 'FccWaypointHubReturnAction' }
      | {
          __typename: 'FccWaypointPickupAction'
          id: string
          estimatedAt: string
          adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename?: 'FccWaypointReallocationAction' }
    >
  }> | null
  fccCurrentAdAlerts: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename?: 'FccAdAlert'
      id: string
      type: Types.FccAdAlertType
    }>
  } | null
  adSeatsLayout: {
    __typename?: 'FccSeatLayout'
    thirdRowRight: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    thirdRowMiddle: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    thirdRowLeft: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowRight: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowMiddle: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowLeft: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
  } | null
}

export const EmergencyStopActionCardVehicleFragmentDoc = gql`
  fragment EmergencyStopActionCardVehicle on Vehicle {
    id
    latestADTelemetry {
      speed {
        value
      }
    }
    fccUpcomingWaypoints {
      ...GetPassengersWaypoint
    }
    ...EmergencyStopResolutionVehicle
    ...CabinSafetyCheckVehicle
    ...DepartureVehicle
  }
  ${GetPassengersWaypointFragmentDoc}
  ${EmergencyStopResolutionVehicleFragmentDoc}
  ${CabinSafetyCheckVehicleFragmentDoc}
  ${DepartureVehicleFragmentDoc}
`
