import { useApolloClient } from '@apollo/client'
import isEqual from 'lodash/isEqual'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import type { ReactNode } from 'react'
import type { Subscription } from 'zen-observable-ts'
import type { FccWaypointPickupAction } from '@backoffice-frontend/graphql'
import { FccPassengerCheckInStatus } from '@backoffice-frontend/graphql'
import type { UsePassengerAuthenticationsWaypointFragment } from './usePassengerAuthentications.hook'
import { PassengerAuthenticationCardStateDocument } from './usePassengerAuthentications.hook'

type WaypointAction =
  UsePassengerAuthenticationsWaypointFragment['actions'][number]

const isPickupAction = (
  action: WaypointAction,
): action is FccWaypointPickupAction => {
  return action.__typename === 'FccWaypointPickupAction'
}

const usePassengerAuthenticationsContextValue = () => {
  const client = useApolloClient()
  const [passengerAuthentications, setPassengerAuthentications] =
    useState<Record<string, FccPassengerCheckInStatus | undefined>>()
  const tripIds = useRef<Array<string>>([])
  const subscriptions = useRef<Array<Subscription>>([])

  const getPassengerAuthentication = (tripId: string) => {
    return passengerAuthentications?.[tripId]
  }

  const subscribeToPassengerAuthentications = (
    waypoint?: UsePassengerAuthenticationsWaypointFragment,
  ) => {
    const newTripIds: Array<string> = []

    waypoint?.actions.forEach(action => {
      if (isPickupAction(action) && action.trip?.id) {
        newTripIds.push(action.trip.id)
      }
    })

    if (newTripIds.length > 0 && !isEqual(newTripIds, tripIds.current)) {
      subscriptions.current.forEach(subscription => subscription.unsubscribe())

      subscriptions.current = newTripIds.map(tripId =>
        client
          .subscribe({
            query: PassengerAuthenticationCardStateDocument,
            variables: { tripId },
          })
          .subscribe({
            next({ data }) {
              setPassengerAuthentications(authentications => {
                return {
                  ...authentications,
                  [tripId]: data?.fccPassengerAuthenticationState.state
                    ? data.fccPassengerAuthenticationState.state
                    : undefined,
                }
              })
            },
            error(error) {
              console.error('Error in subscription', error)
            },
          }),
      )
    }

    tripIds.current = newTripIds
  }

  useEffect(() => {
    // when component unmounts, unsubscribe from all subscriptions
    return () => {
      subscriptions.current.forEach(subscription => {
        subscription.unsubscribe()
      })
    }
  }, [])

  const hasPassengerAuthentications =
    passengerAuthentications && Object.keys(passengerAuthentications).length > 0

  // 1. All passengers are checked in -> boarding step
  // 2. All passengers are cancelled -> departure
  // 3. All passengers are no-show -> departure
  // 4. Some passengers are checked in and some cancelled or no-show -> boarding step

  const isAllPassengersCheckedIn =
    hasPassengerAuthentications &&
    Object.values(passengerAuthentications).every(isConfirmed)

  const isAllManualNoShowConfirmed =
    hasPassengerAuthentications &&
    Object.values(passengerAuthentications).every(isManualNoShow)

  const isAllPassengersCancelledTrip =
    hasPassengerAuthentications &&
    Object.values(passengerAuthentications).every(isCancelled)

  const isSomePassengerCheckedIn =
    hasPassengerAuthentications &&
    Object.values(passengerAuthentications).some(isConfirmed)

  const isSomeManualNoShowConfirmed =
    hasPassengerAuthentications &&
    Object.values(passengerAuthentications).some(
      state => state === FccPassengerCheckInStatus.NoShowTriggered,
    )

  const isSomePassengerCancelledTrip =
    hasPassengerAuthentications &&
    Object.values(passengerAuthentications).some(
      state => state === FccPassengerCheckInStatus.Cancelled,
    )

  const isAllAuthenticationsCancelled =
    (isSomeManualNoShowConfirmed &&
      isSomePassengerCancelledTrip &&
      !isSomePassengerCheckedIn) ||
    isAllPassengersCancelledTrip ||
    isAllManualNoShowConfirmed

  return {
    isAllAuthenticationsCancelled,
    isAllPassengersCheckedIn:
      isAllPassengersCheckedIn ||
      (isSomePassengerCheckedIn && isSomePassengerCancelledTrip) ||
      (isSomePassengerCheckedIn && isSomeManualNoShowConfirmed),
    getPassengerAuthentication,
    subscribeToPassengerAuthentications,
  }
}

type PassengerAuthenticationsContextValue = ReturnType<
  typeof usePassengerAuthenticationsContextValue
>

const PassengerAuthenticationsContext =
  createContext<PassengerAuthenticationsContextValue | null>(null)

export const PassengerAuthenticationsProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const passengerAuthenticationsContextValue =
    usePassengerAuthenticationsContextValue()

  return (
    <PassengerAuthenticationsContext.Provider
      value={passengerAuthenticationsContextValue}
    >
      {children}
    </PassengerAuthenticationsContext.Provider>
  )
}

export const usePassengerAuthentications = () => {
  const passengerAuthenticationsContextValue = useContext(
    PassengerAuthenticationsContext,
  )

  if (passengerAuthenticationsContextValue === null) {
    throw new Error(
      'usePassengerAuthentications must be used within PassengerAuthenticationsProvider',
    )
  }

  return passengerAuthenticationsContextValue
}

const isManualNoShow = (authenticationState?: FccPassengerCheckInStatus) =>
  authenticationState !== undefined &&
  authenticationState === FccPassengerCheckInStatus.NoShowTriggered

const isCancelled = (authenticationState?: FccPassengerCheckInStatus) =>
  authenticationState !== undefined &&
  authenticationState === FccPassengerCheckInStatus.Cancelled

const isConfirmed = (authenticationState?: FccPassengerCheckInStatus) =>
  authenticationState !== undefined &&
  authenticationState === FccPassengerCheckInStatus.Confirmed
