import { QRCodeCanvas as QRCode } from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import { useAuthQrCode } from '@backoffice-frontend/authentication'
import {
  Box,
  Link,
  TextField,
  Body1,
  MoiaButton,
  H6,
  MoiaGrid,
} from '@backoffice-frontend/patterns'
import { AccountViewDataTestIds } from '../../AccountViewDataTestIds'

export const MFARegistrationView = ({
  error,
  handleTokenVerifyClick,
  setValidationToken,
}: {
  error?: string
  handleTokenVerifyClick: () => Promise<void>
  setValidationToken: (token: string) => void
}) => {
  const { code, qrCodeUrl } = useAuthQrCode()
  const { t } = useTranslation()

  const setTranslatedError = (error?: string) => {
    switch (error) {
      case 'InvalidParameterException':
        return t('authenticatedAppView_mfaCodeInvalidCharacter')
      case 'EnableSoftwareTokenMFAException':
        return t('authenticatedAppView_mfaCodeInvalidCode')
      default:
        return t('authenticatedAppView_mfaCodeError')
    }
  }

  return (
    <>
      <H6 paragraph>01 {t('authenticatedAppView_mfaInstructionInstallApp')}</H6>
      <MoiaGrid>
        <Link
          href="https://play.google.com/store/apps/details?id=com.okta.android.auth&hl=de"
          target="_blank"
        >
          {t('authenticatedAppView_mfaOktaApp_textlink')}
        </Link>
        <Link
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
          target="_blank"
        >
          {t('authenticatedAppView_mfaGoogleApp_textlink')}
        </Link>
      </MoiaGrid>

      <Box sx={{ my: 4 }}>
        <H6 paragraph>
          02 {t('authenticatedAppView_mfaInstructionScanQRCode')}
        </H6>
        {qrCodeUrl && (
          <Box sx={{ my: 4 }}>
            <QRCode size={150} value={qrCodeUrl} />
          </Box>
        )}
        <Body1>{t('authenticatedAppView_mfaInstructionManualEntry')}</Body1>
        <Body1 style={{ wordWrap: 'break-word' }}>{code}</Body1>
      </Box>

      <H6 paragraph>03 {t('authenticatedAppView_mfaInstructionEnterCode')}</H6>
      <MoiaGrid gridTemplateColumns="1fr max-content">
        <TextField
          fullWidth
          id="Mfa.RegisterField"
          error={Boolean(error)}
          onChange={e => setValidationToken(e.target.value)}
          placeholder="112233"
          helperText={error && setTranslatedError(error)}
        />

        <MoiaButton
          data-testid={AccountViewDataTestIds.Mfa.VerifyButton}
          color="primary"
          onClick={handleTokenVerifyClick}
        >
          {t('authenticatedAppView_mfaVerifyCode_button')}
        </MoiaButton>
      </MoiaGrid>
    </>
  )
}
