import { useErrorHandlingQuery } from '@backoffice-frontend/data-fetching'
import type { BackofficeUserManagementGroupsQuery } from './graphql/backofficeGroupsList.hook'
import { useBackofficeUserManagementGroupsQuery } from './graphql/backofficeGroupsList.hook'

export type BackofficeGroups = NonNullable<
  BackofficeUserManagementGroupsQuery['backofficeUserManagementGroups']
>
export const useBackofficeGroups = () => {
  const { data, loading } = useErrorHandlingQuery(
    useBackofficeUserManagementGroupsQuery,
    { variables: {} },
  )

  const groups = data?.backofficeUserManagementGroups

  return {
    data: groups,
    loading,
  }
}
