import { useTranslation } from 'react-i18next'
import { Button, Text } from '@moia-dev/pace-web'
import { MoiaGrid } from '@backoffice-frontend/patterns'
import { LoginViewAreaId } from '../LoginViewAreaId'
import { useIsSystemUser } from './useIsSystemUser'

export const MultiFactorAuthenticationInformation = ({
  onNextClick,
  isMobile,
}: {
  onNextClick: VoidFunction
  isMobile: boolean
}) => {
  const { t } = useTranslation(LoginViewAreaId)
  useIsSystemUser()
  return (
    <MoiaGrid mt={isMobile ? 1 : 3} gridGap={isMobile ? 1 : 2}>
      <Text>
        {t(
          'Why do we use two-step verification? Passwords can be stolen – especially when the same password is used for several websites. By adding two-step verification, your Backoffice Account remains secure even if your password gets stolen',
        )}
      </Text>
      <MoiaGrid justifyContent="end" gridTemplateColumns="max-content" mt={1}>
        <Button
          onClick={onNextClick}
          variant="primary"
          type="submit"
          size="compact"
          label={t('Continue')}
        />
      </MoiaGrid>
    </MoiaGrid>
  )
}
