import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EyeClosedIcon, EyeIcon } from '@moia-dev/pace-icons'
import { Button, LoadingIndicator } from '@moia-dev/pace-web'
import { Routes } from '@backoffice-frontend/common'
import { FormTextfield } from '@backoffice-frontend/forms'
import { MoiaGrid, useTheme } from '@backoffice-frontend/patterns'
import { LoginViewAreaId } from '../LoginViewAreaId'

export type PasswordChallengeValues = {
  newPassword: string
  newPasswordRepeat: string
}

export const PasswordChallengeForm = ({
  onSubmit,
  isMobile,
}: {
  onSubmit: (values: PasswordChallengeValues) => Promise<void>
  isMobile: boolean
}) => {
  const { t } = useTranslation(LoginViewAreaId)
  const { handleSubmit, control, formState, getValues } =
    useForm<PasswordChallengeValues>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    })
  const navigate = useNavigate()
  const validatePasswordsMatch = (value: string) => {
    const { newPassword } = getValues()
    return value === newPassword || t('Passwords do not match')
  }
  const theme = useTheme()
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginTop: isMobile ? theme.space.Space2 : theme.space.Space6 }}
    >
      <MoiaGrid mt={1} gridGap={isMobile ? 1 : 3}>
        <FormTextfield
          control={control}
          name="newPassword"
          type={showPassword ? 'text' : 'password'}
          label={t('New password')}
          required
          removeSpaces
          trailing={
            showPassword ? (
              <EyeIcon onClick={() => setShowPassword(false)} />
            ) : (
              <EyeClosedIcon onClick={() => setShowPassword(true)} />
            )
          }
        />
        <FormTextfield
          control={control}
          name="newPasswordRepeat"
          type={showPasswordRepeat ? 'text' : 'password'}
          label={t('Repeat password')}
          required
          removeSpaces
          rules={{ validate: validatePasswordsMatch }}
          trailing={
            showPasswordRepeat ? (
              <EyeIcon onClick={() => setShowPasswordRepeat(false)} />
            ) : (
              <EyeClosedIcon onClick={() => setShowPasswordRepeat(true)} />
            )
          }
        />
        <MoiaGrid
          gridTemplateColumns="max-content max-content"
          justifyContent="end"
          gridGap={1}
        >
          <Button
            type="button"
            onClick={() => navigate(Routes.Auth.Login.url)}
            label={t('Back')}
          />
          <Button
            type="submit"
            disabled={!formState.isValid}
            color="primary"
            icon={formState.isSubmitting ? <LoadingIndicator /> : undefined}
            label={t('Submit')}
          />
        </MoiaGrid>
      </MoiaGrid>
    </form>
  )
}
