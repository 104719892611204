import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Callout } from '@moia-dev/pace-web'
import { useSetCognitoUser } from '@backoffice-frontend/authentication'
import { challengePassword, isAuthenticated } from '@backoffice-frontend/common'
import { LoginViewAreaId } from '../LoginViewAreaId'
import { PublicAppViewContentLayout } from '../components/PublicAppViewContentLayout'
import { PasswordChallengeForm } from './PasswordChallengeForm'

export const PasswordChallengeView = ({ isMobile }: { isMobile: boolean }) => {
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const { t } = useTranslation(LoginViewAreaId)

  const handleValidationError = (error: { code: string }) => {
    setError(
      error?.code === 'InvalidPasswordException'
        ? t('passwordPolicy')
        : t('Your credentials are wrong. Please try again or contact support'),
    )
  }
  const setCognitoUser = useSetCognitoUser()
  const handleSubmitForm = async ({ newPassword = '' }) => {
    try {
      await challengePassword(newPassword)
      const user = await isAuthenticated()
      if (user) {
        setCognitoUser?.(user)
        navigate('/')
      }
      throw Error('no current user')
    } catch (e) {
      //@ts-expect-error there is a default
      handleValidationError(e)
      console.info(e)
    }
  }

  return (
    <PublicAppViewContentLayout title={t('Reset Password')} isMobile={isMobile}>
      {error && (
        <div
          css={theme => ({
            marginTop: isMobile ? theme.space.Space2 : theme.space.Space6,
            whiteSpace: 'pre-line',
          })}
        >
          <Callout variant="neutral">{error}</Callout>
        </div>
      )}
      <PasswordChallengeForm onSubmit={handleSubmitForm} isMobile={isMobile} />
    </PublicAppViewContentLayout>
  )
}
