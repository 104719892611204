import { forwardRef, useId } from 'react'
import type { FieldValues, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MinusIcon, PlusIcon } from '@moia-dev/pace-icons'
import {
  FormField,
  IconButton,
  Input,
  type FormFieldProps,
  type InputProps,
} from '@moia-dev/pace-web'
import { isIntegerOrEmpty } from '@backoffice-frontend/report-io-lib'
import { FormsAreaId } from '../FormsAreaId'

export type QuantityControlFieldProps = Pick<
  FormFieldProps,
  'label' | 'caption' | 'validation' | 'hideCharCount'
> &
  InputProps & {
    setValue: UseFormSetValue<FieldValues>
  }

const MAX_VALUE = 99
const MIN_VALUE = 0

export const QuantityControl = forwardRef<
  HTMLInputElement,
  QuantityControlFieldProps
>(
  (
    {
      label,
      name,
      caption,
      id: maybeId,
      validation,
      value,
      required,
      onChange,
      setValue,
      ...props
    }: QuantityControlFieldProps,
    ref,
  ) => {
    const { t } = useTranslation(FormsAreaId)
    const generatedId = useId()
    const id = maybeId ? maybeId : generatedId

    const decrease = () => {
      const newValue = value === '' ? MIN_VALUE : Number(value) - 1
      if (newValue < MIN_VALUE) {
        return
      }
      name && setValue(name, String(newValue))
    }

    const increase = () => {
      const newValue = value === '' ? MIN_VALUE : Number(value) + 1
      if (newValue > MAX_VALUE) {
        return
      }
      name && setValue(name, String(newValue))
    }

    return (
      <FormField
        required={required}
        value={typeof value === 'string' ? value : undefined}
        caption={caption}
        label={label}
        htmlFor={id}
        validation={validation}
        css={{ gridTemplateColumns: '1fr 1fr' }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
          }}
        >
          <IconButton
            variant="secondary"
            size="compact"
            css={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              boxShadow: 'none !important',
            }}
            icon={<MinusIcon />}
            disabled={value === '0' || value === ''}
            aria-label={t('Decrease')}
            onClick={decrease}
          />
          <Input
            id={id}
            ref={ref}
            size="compact"
            css={theme => ({
              borderRadius: 0,
              minWidth: 45,
              maxWidth: 45,
              textAlign: 'center',
              borderLeft: 0,
              borderRight: 0,
              borderColor: theme.semantic.ColorBorderOnActionSecondaryDefault,
            })}
            {...{
              ...props,
              required,
              onChange: e => {
                const value = e.target.value
                if (isIntegerOrEmpty(value)) {
                  const newValue = value === '' ? MIN_VALUE : Number(value)
                  if (newValue > MAX_VALUE) {
                    e.preventDefault()
                    return
                  }
                  onChange &&
                    onChange({
                      ...e,
                      target: { ...e.target, value: String(newValue) },
                    })
                } else {
                  e.preventDefault()
                }
              },
              value,
            }}
          />
          <IconButton
            variant="secondary"
            size="compact"
            css={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              boxShadow: 'none !important',
            }}
            icon={<PlusIcon />}
            disabled={value === '99'}
            aria-label={t('Increase')}
            onClick={increase}
          />
        </div>
      </FormField>
    )
  },
)
