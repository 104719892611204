import type { FormRenderProps } from 'react-final-form'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import type { BackofficeStatus } from '@backoffice-frontend/graphql'
import { BackofficeStatusType } from '@backoffice-frontend/graphql'
import {
  MoiaFormDropdown,
  MoiaFormTextField,
  MoiaGrid,
} from '@backoffice-frontend/patterns'
import {
  useValidators,
  composeValidators,
} from '@backoffice-frontend/validators'
import { StatusesFormFieldWrapper } from './StatusesFormFieldWrapper'
import { getTranslatedType } from './translateBackofficeStatus'

export type BackofficeStatusFormInput = Pick<
  BackofficeStatus,
  'type' | 'title' | 'notes'
>

type BackofficeStatusesFormProps = {
  onSubmit: FormRenderProps['handleSubmit']
  values: BackofficeStatusFormInput
}

export const BackofficeStatusForm = ({
  onSubmit,
  values,
}: BackofficeStatusesFormProps) => {
  const { t } = useTranslation()

  const TITLE_LENGTH = 80
  const NOTES_LENGTH = 500
  const types = Object.values(BackofficeStatusType)
  const required = useValidators().required

  return (
    <form
      onSubmit={onSubmit}
      css={{
        minWidth: '800px',
      }}
    >
      <StatusesFormFieldWrapper
        title={t('status_dialog_statusType_header')}
        hasSeparator
      >
        <Field
          component={MoiaFormDropdown}
          label={t('status_dialog_statusType_label')}
          name="type"
          id="type"
          required
          options={types.map(type => ({
            value: type,
            label: getTranslatedType(t, type),
          }))}
          validate={composeValidators([required])}
        />
      </StatusesFormFieldWrapper>
      <StatusesFormFieldWrapper
        title={t('status_dialog_statusTitle_header')}
        tooltipText={{
          title: t('status_tooltip_statusTitle'),
          paragraphs: [],
        }}
        hasSeparator
      >
        <MoiaGrid>
          <Field
            component={MoiaFormTextField}
            required
            label={t('common_title_label')}
            name="title"
            id="title"
            inputProps={{ maxLength: TITLE_LENGTH }}
            validate={composeValidators([required])}
            helperText={t('common_charactersLeft_label', {
              length: values.title
                ? TITLE_LENGTH - values.title.length
                : TITLE_LENGTH,
            })}
          />
        </MoiaGrid>
      </StatusesFormFieldWrapper>
      <StatusesFormFieldWrapper
        title={t('common_description_label')}
        tooltipText={{
          title: t('status_tooltip_statusDescription'),
          paragraphs: [
            t('common_textformattingBullet'),
            t('common_textformattingBold'),
            t('common_textformattingStrikethrough'),
          ],
        }}
      >
        <MoiaGrid gridGap={4}>
          <Field
            component={MoiaFormTextField}
            required
            multiline
            rows={8}
            variant="outlined"
            label={t('common_description_label')}
            name="notes"
            id="notes"
            inputProps={{ maxLength: NOTES_LENGTH }}
            validate={composeValidators([required])}
            helperText={t('common_charactersLeft_label', {
              length: values.notes
                ? NOTES_LENGTH - values.notes.length
                : NOTES_LENGTH,
            })}
          />
        </MoiaGrid>
      </StatusesFormFieldWrapper>
    </form>
  )
}
