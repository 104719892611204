/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'
import {
  IsBookableFragmentDoc,
  InLocationFragmentDoc,
  HasHubReturnNextFragmentDoc,
  IsRebalancingFragmentDoc,
  HasSafetyCheckFragmentDoc,
  IsNotBookableFragmentDoc,
  IsLowOnEnergyFragmentDoc,
  HasNoActiveMissionFragmentDoc,
  IsWavFragmentDoc,
} from '../../../../../../vehicle-common/src/lib/utils/vehicleValidators.hook'

export type FilterByBookableOptionVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  inService: boolean | null
  fccUpcomingWaypoints: Array<{
    __typename?: 'FccWaypoint'
    actions: Array<
      | { __typename: 'FccWaypointDeliveryAction' }
      | { __typename: 'FccWaypointHubReturnAction' }
      | { __typename: 'FccWaypointPickupAction' }
      | { __typename: 'FccWaypointReallocationAction' }
    >
  }> | null
}

export const FilterByBookableOptionVehicleFragmentDoc = gql`
  fragment FilterByBookableOptionVehicle on Vehicle {
    id
    ...IsBookable
    fccUpcomingWaypoints {
      actions {
        __typename
      }
    }
  }
  ${IsBookableFragmentDoc}
`
