/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type AdOperatorOverviewQueryVariables = Types.Exact<{
  serviceAreaUuid: Types.Scalars['ID']['input']
}>

export type AdOperatorOverviewQuery = {
  __typename?: 'Query'
  availabilities: {
    __typename?: 'FccOperatorAvailabilityCountInServiceArea'
    busy: number
    break: number
    available: number
  }
}

export const AdOperatorOverviewDocument = gql`
  query AdOperatorOverview($serviceAreaUuid: ID!) {
    availabilities: fccAllOperatorAvailabilities(
      serviceAreaUUID: $serviceAreaUuid
    ) {
      busy
      break
      available
    }
  }
`

/**
 * __useAdOperatorOverviewQuery__
 *
 * To run a query within a React component, call `useAdOperatorOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdOperatorOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdOperatorOverviewQuery({
 *   variables: {
 *      serviceAreaUuid: // value for 'serviceAreaUuid'
 *   },
 * });
 */
export function useAdOperatorOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdOperatorOverviewQuery,
    AdOperatorOverviewQueryVariables
  > &
    (
      | { variables: AdOperatorOverviewQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AdOperatorOverviewQuery,
    AdOperatorOverviewQueryVariables
  >(AdOperatorOverviewDocument, options)
}
export function useAdOperatorOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdOperatorOverviewQuery,
    AdOperatorOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AdOperatorOverviewQuery,
    AdOperatorOverviewQueryVariables
  >(AdOperatorOverviewDocument, options)
}
export function useAdOperatorOverviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdOperatorOverviewQuery,
        AdOperatorOverviewQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AdOperatorOverviewQuery,
    AdOperatorOverviewQueryVariables
  >(AdOperatorOverviewDocument, options)
}
export type AdOperatorOverviewQueryHookResult = ReturnType<
  typeof useAdOperatorOverviewQuery
>
export type AdOperatorOverviewLazyQueryHookResult = ReturnType<
  typeof useAdOperatorOverviewLazyQuery
>
export type AdOperatorOverviewSuspenseQueryHookResult = ReturnType<
  typeof useAdOperatorOverviewSuspenseQuery
>
export type AdOperatorOverviewQueryResult = Apollo.QueryResult<
  AdOperatorOverviewQuery,
  AdOperatorOverviewQueryVariables
>
