import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import {
  MoiaTopBar,
  MoiaTopBarTitle,
  MoiaViewBox,
  ReleaseNotesIcon,
} from '@backoffice-frontend/patterns'
import { BackofficeUpdatesTable } from './BackofficeUpdatesTable'

export const BackofficeUpdatesView = () => {
  const { t } = useTranslation()
  return (
    <>
      <Helmet>
        <title>{t('updates_header')}</title>
      </Helmet>
      <MoiaTopBar
        leading={
          <MoiaTopBarTitle
            icon={<ReleaseNotesIcon />}
            title={t('updates_header')}
          />
        }
      />
      <MoiaViewBox>
        <BackofficeUpdatesTable />
      </MoiaViewBox>
    </>
  )
}
