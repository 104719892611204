// This file was once generated when you created this area
// but you can edit it freely
import { transformRoutes } from '@backoffice-frontend/common'

const BasePassengerAppAreaRoutes = {
  route: '/passenger-app',
}

export const PassengerAppAreaRoutes = transformRoutes(
  BasePassengerAppAreaRoutes,
)
