import { useEffect, useMemo, useState } from 'react'
import { createContext } from 'react'
import { useParams } from 'react-router-dom'
import { useServiceAreasQuery } from '@backoffice-frontend/common'
import { useBoumClaimsConsumer } from '@backoffice-frontend/restricted'
import { useRioTranslation } from '../utils/useReportIoTranslation'

type ServiceArea = {
  uuid: string
  id: string
  name: string
}

export type ReportIoServiceAreaContextProps = {
  serviceAreas: ServiceArea[]
  serviceAreaUuid: string | null
  loading: boolean
  children?: React.ReactNode
  error: Error | undefined
  setServiceAreaUuid: (uuid: string | null) => void
}

export const ReportIoServiceAreaContext =
  createContext<ReportIoServiceAreaContextProps>({
    serviceAreas: [],
    serviceAreaUuid: '',
    loading: false,
    error: undefined,
    setServiceAreaUuid: () => {},
  })

export const ReportIoServiceAreaContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { serviceAreaUuid: paramServiceAreaUuid = null } = useParams<{
    serviceAreaUuid: string
  }>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | undefined>(undefined)
  const tRio = useRioTranslation()
  const boumClaims = useBoumClaimsConsumer()
  const { data, error: loadingServiceAreasError } = useServiceAreasQuery({
    fetchPolicy: 'cache-and-network',
  })
  const [serviceAreaUuid, setServiceAreaUuid] = useState<string | null>(null)

  const serviceAreas: ServiceArea[] = useMemo(() => {
    return (
      data?.serviceAreas
        .filter(serviceArea =>
          boumClaims.serviceAreas().includes(serviceArea.uuid),
        )
        .map(serviceArea => ({
          uuid: serviceArea.uuid,
          id: serviceArea.id,
          name: tRio({
            en: serviceArea.displayNames.en ?? '',
            de: serviceArea.displayNames.de ?? '',
          }),
        })) ?? []
    )
  }, [data, boumClaims, tRio])

  useEffect(() => {
    if (!loading && paramServiceAreaUuid) {
      setServiceAreaUuid(paramServiceAreaUuid)
    }
  }, [loading, paramServiceAreaUuid])

  useEffect(() => {
    if (loadingServiceAreasError) {
      setError(new Error('Failed to load service areas'))
    }
  }, [loadingServiceAreasError])

  useEffect(() => {
    if (serviceAreas.length > 0) {
      setLoading(false)
    }
  }, [serviceAreas.length])

  useEffect(() => {
    if (
      !loading &&
      serviceAreaUuid !== null &&
      paramServiceAreaUuid !== null &&
      serviceAreas.length > 0 &&
      !serviceAreas.find(
        serviceArea => serviceArea.uuid === paramServiceAreaUuid,
      ) &&
      !serviceAreas.find(serviceArea => serviceArea.uuid === serviceAreaUuid) &&
      !error
    ) {
      setError(new Error('Failed to load service areas'))
    }
  }, [error, loading, paramServiceAreaUuid, serviceAreaUuid, serviceAreas])

  return (
    <ReportIoServiceAreaContext.Provider
      value={{
        serviceAreas,
        serviceAreaUuid,
        loading,
        error,
        setServiceAreaUuid,
      }}
    >
      {children}
    </ReportIoServiceAreaContext.Provider>
  )
}
