import { useTranslation } from 'react-i18next'
import {
  useErrorHandlingMutation,
  useErrorHandlingQuery,
} from '@backoffice-frontend/data-fetching'
import { useToggle } from '@backoffice-frontend/hooks'
import {
  MoiaTableButton,
  useMoiaSuccessNotification,
} from '@backoffice-frontend/patterns'
import {
  BackofficeStatusesDocument,
  useBackofficeStatusByIdQuery,
  useBackofficeStatusUpdateMutation,
} from '@backoffice-frontend/status-api'
import { BackofficeStatusDialogContent } from './BackofficeStatusDialogContent'
import type { BackofficeStatusFormInput } from './BackofficeStatusForm'

type BackofficeStatusUpdateDialogProps = {
  id?: string
}

export const BackofficeStatusUpdateDialog = ({
  id,
}: BackofficeStatusUpdateDialogProps) => {
  const { t } = useTranslation()
  const [showDialogForm, toggleDialogForm] = useToggle()
  const skip = !id || !showDialogForm

  const { data, loading: dataLoading } = useErrorHandlingQuery(
    useBackofficeStatusByIdQuery,
    {
      variables: { id: id ?? '' },
      skip,
    },
  )

  const status = data?.backofficeStatusById

  const submitBackofficeStatus = (input: BackofficeStatusFormInput) => {
    void backofficeStatusUpdate({
      variables: {
        input: {
          id: id ?? '',
          creationDate: status?.creationDate ?? new Date().toISOString(),
          ...input,
        },
      },
      refetchQueries: [BackofficeStatusesDocument],
    })
  }

  const { enqueueMoiaSuccessNotification } = useMoiaSuccessNotification()

  const [backofficeStatusUpdate, { loading }] = useErrorHandlingMutation(
    useBackofficeStatusUpdateMutation,
    {
      onCompleted: result => {
        if (result.backofficeStatusUpdate) {
          enqueueMoiaSuccessNotification(t('status_toast_updateStatusSuccess'))
          toggleDialogForm()
        }
      },
    },
  )

  return (
    <div onClick={e => e.stopPropagation()}>
      {!dataLoading && (
        <BackofficeStatusDialogContent
          initialValues={{
            type: status?.type,
            title: status?.title,
            notes: status?.notes,
          }}
          onSubmit={submitBackofficeStatus}
          header={t('status_dialog_updateStatus_header')}
          loading={loading || dataLoading}
          openDialog={showDialogForm}
          id={id}
          toggleOpenDialog={toggleDialogForm}
          type="edit"
        />
      )}
      <MoiaTableButton className="revealOnHover" onClick={toggleDialogForm}>
        {t('common_edit_button')}
      </MoiaTableButton>
    </div>
  )
}
