/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'
import { VehicleGroupFragmentDoc } from '../../../../../vehicle-common/src/lib/graphql/fragment/vehicleGroup.hook'

const defaultOptions = {} as const
export type VehicleDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type VehicleDetailsQuery = {
  __typename?: 'Query'
  vehicleByID: {
    __typename?: 'Vehicle'
    id: string
    concessionType: Types.VehicleConcessionType | null
    changeReasonFreeText: string | null
    currentlyOpen: boolean
    deFleetingDate: string | null
    engineType: Types.VehicleEngineType | null
    firstRegistrationDate: string | null
    highVoltageBatteryLevel: number | null
    inactiveSince: string | null
    inFleetingDate: string | null
    isSelfDriving: boolean
    isWheelchairAccessible: boolean | null
    label: number | null
    licensePlate: string | null
    nextMainInspectionDate: string | null
    openedBy: Types.VehicleInteractionUserType | null
    serviceAreaID: string
    serviceAreaUUID: string
    state: Types.VehicleState
    tenant: string
    vin: string | null
    baseHub: {
      __typename?: 'Hub'
      id: string
      name: string
      displayName: string
    } | null
    currentlyLocatedInHub: {
      __typename?: 'Hub'
      displayName: string
      id: string
    } | null
    fleet: { __typename?: 'Fleet'; id: string; name: string } | null
    hardwareConfiguration: {
      __typename?: 'HardwareConfiguration'
      keyRfid: string
      boxPc: {
        __typename?: 'BoxPc'
        artifactName: string | null
        greengrassDeviceId: string
        greengrassProvisioningState: Types.GreengrassProvisioningState | null
        cpuId: string
        macAddr: string
        provisioningState: Types.ProvisioningState
      }
      camera: {
        __typename?: 'Camera'
        macAddr: string
        serialNumber: string | null
      }
      cdisBox: { __typename?: 'CdisBox'; imei: string; macAddr: string }
      router: {
        __typename?: 'Router'
        macAddr: string
        provisioningState: Types.ProvisioningState
        serialNumber: string
      }
      tablet: {
        __typename?: 'Tablet'
        phoneNumber: string | null
        imei: string
      } | null
    } | null
    homeHub: {
      __typename?: 'Hub'
      id: string
      serviceAreaUUID: string
      city: string
      contact: string
      displayName: string
      geometry: string
      name: string
      phoneNumber: string
      postcode: string
      streetAndNumber: string
      location: { __typename?: 'Location'; latitude: number; longitude: number }
    } | null
    ignition: { __typename?: 'Ignition'; updatedAt: string | null } | null
    lastVehicleStateChange: {
      __typename?: 'VehicleStateChange'
      vehicleStateChangeReason: Types.VehicleStateChangeReason | null
      changeReasonFreeText: string | null
      createdAt: string | null
      expectedActivationAt: string | null
      updatedAt: string | null
      createdByUser: {
        __typename?: 'BackofficeUser'
        firstName: string
        lastName: string
      } | null
      updatedBy: {
        __typename?: 'BackofficeUser'
        firstName: string
        lastName: string
      } | null
    } | null
    lastVehicleInteraction: {
      __typename?: 'VehicleInteraction'
      type: Types.VehicleInteractionType
      isBackofficeInteraction: boolean
      reason: string | null
      timestamp: string
      hub: { __typename?: 'Hub'; displayName: string } | null
      employee: {
        __typename?: 'Employee'
        id: string
        firstName: string | null
        lastName: string | null
        roles: Array<Types.Role>
      } | null
    } | null
    latestLocation: {
      __typename?: 'VehicleLocation'
      location: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      } | null
    } | null
    nextHubReturn: { __typename?: 'HubReturn'; isInFieldBreak: boolean } | null
    scheduledStateChanges: Array<{
      __typename?: 'ScheduledVehicleStateChange'
      id: string
      scheduledAt: string
      vehicleStateChange: {
        __typename?: 'VehicleStateChange'
        changeReasonFreeText: string | null
        vehicleStateChangeReason: Types.VehicleStateChangeReason | null
        expectedActivationAt: string | null
      }
    }> | null
    seatConfiguration: {
      __typename?: 'VehicleSeatConfiguration'
      available: number
      total: number
      availableWheelchairSeats: number | null
      childSeatSpecs: Array<{
        __typename?: 'ChildSeatSpec'
        isBoosterSeat: boolean
        isChildSeat: boolean
        isAvailable: boolean
        seatNumber: number
      }>
    } | null
    vgaVersion: {
      __typename?: 'VGAVersionInfo'
      appVersion: string | null
    } | null
    vehicleGroup: {
      __typename?: 'VehicleGroup'
      id: string
      groupType: Types.VehicleGroupType
      boxProvider: Types.BoxProvider
      engineType: Types.VehicleEngineType
      displayName: string
      minStateOfCharge: number
      tenant: string
      seats: number
      wheelchairSeats: number
      sdsProvider: Types.SdsProvider
      serviceAreaUUIDs: Array<string>
      isWheelchairAccessible: boolean
      childSeatSpecs: Array<{
        __typename?: 'ChildSeatSpec'
        seatNumber: number
        isBoosterSeat: boolean
        isChildSeat: boolean
        isAvailable: boolean
      }>
    } | null
    inVehicleLists: Array<{
      __typename?: 'VehicleList'
      id: string
      name: string
    }>
  }
}

export const VehicleDetailsDocument = gql`
  query vehicleDetails($id: ID!) {
    vehicleByID(id: $id) {
      id
      baseHub {
        id
        name
        displayName
      }
      concessionType
      changeReasonFreeText
      currentlyLocatedInHub {
        displayName
        id
      }
      currentlyOpen
      deFleetingDate
      engineType
      firstRegistrationDate
      fleet {
        id
        name
      }
      hardwareConfiguration {
        boxPc {
          artifactName
          greengrassDeviceId
          greengrassProvisioningState
          cpuId
          macAddr
          provisioningState
        }
        camera {
          macAddr
          serialNumber
        }
        cdisBox {
          imei
          macAddr
        }
        keyRfid
        router {
          macAddr
          provisioningState
          serialNumber
        }
        tablet {
          phoneNumber
          imei
        }
      }
      highVoltageBatteryLevel
      homeHub {
        id
        serviceAreaUUID
        city
        contact
        displayName
        geometry
        location {
          latitude
          longitude
        }
        name
        phoneNumber
        postcode
        streetAndNumber
      }
      ignition {
        updatedAt
      }
      inactiveSince
      inFleetingDate
      isSelfDriving
      isWheelchairAccessible
      label
      lastVehicleStateChange {
        vehicleStateChangeReason
        changeReasonFreeText
        createdAt
        createdByUser {
          firstName
          lastName
        }
        expectedActivationAt
        updatedBy {
          firstName
          lastName
        }
        updatedAt
      }
      lastVehicleInteraction {
        type
        isBackofficeInteraction
        reason
        hub {
          displayName
        }
        employee {
          id
          firstName
          lastName
          roles
        }
        timestamp
      }
      latestLocation {
        location {
          latitude
          longitude
        }
      }
      licensePlate
      nextHubReturn {
        isInFieldBreak
      }
      nextMainInspectionDate
      openedBy
      scheduledStateChanges {
        id
        scheduledAt
        vehicleStateChange {
          changeReasonFreeText
          vehicleStateChangeReason
          expectedActivationAt
        }
      }
      seatConfiguration {
        available
        total
        childSeatSpecs {
          isBoosterSeat
          isChildSeat
          isAvailable
          seatNumber
        }
        availableWheelchairSeats
      }
      serviceAreaID
      serviceAreaUUID
      state
      tenant
      vgaVersion {
        appVersion
      }
      vehicleGroup {
        ...VehicleGroup
      }
      vin
      inVehicleLists {
        id
        name
      }
    }
  }
  ${VehicleGroupFragmentDoc}
`

/**
 * __useVehicleDetailsQuery__
 *
 * To run a query within a React component, call `useVehicleDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVehicleDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleDetailsQuery,
    VehicleDetailsQueryVariables
  > &
    (
      | { variables: VehicleDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VehicleDetailsQuery, VehicleDetailsQueryVariables>(
    VehicleDetailsDocument,
    options,
  )
}
export function useVehicleDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleDetailsQuery,
    VehicleDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VehicleDetailsQuery, VehicleDetailsQueryVariables>(
    VehicleDetailsDocument,
    options,
  )
}
export function useVehicleDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VehicleDetailsQuery,
        VehicleDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    VehicleDetailsQuery,
    VehicleDetailsQueryVariables
  >(VehicleDetailsDocument, options)
}
export type VehicleDetailsQueryHookResult = ReturnType<
  typeof useVehicleDetailsQuery
>
export type VehicleDetailsLazyQueryHookResult = ReturnType<
  typeof useVehicleDetailsLazyQuery
>
export type VehicleDetailsSuspenseQueryHookResult = ReturnType<
  typeof useVehicleDetailsSuspenseQuery
>
export type VehicleDetailsQueryResult = Apollo.QueryResult<
  VehicleDetailsQuery,
  VehicleDetailsQueryVariables
>
