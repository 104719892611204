import store from 'store'
import { uiNamespace } from '@backoffice-frontend/common'
import { useErrorHandlingMutation } from '@backoffice-frontend/data-fetching'
import { type BackofficeUser } from '@backoffice-frontend/graphql'
import {
  ListItem,
  ListItemText,
  MoiaButton,
  useMoiaErrorNotification,
} from '@backoffice-frontend/patterns'
import { useBackofficeUserManagementSwapGroupsMutation } from './swapUserWithGroups.hook'

const GROUP_SWITCHER_FALLBACK = `${uiNamespace}.groupSwitcherReset`
export const persistUser = (backofficeUser?: Partial<BackofficeUser>) => {
  if (store.get(GROUP_SWITCHER_FALLBACK)) {
    return
  }
  if (backofficeUser?.timedGroups) {
    store.set(
      GROUP_SWITCHER_FALLBACK,
      backofficeUser.timedGroups.map(timed => timed?.groupName),
    )
  }
}

export const ResetUserButton = ({
  handleRefreshToken,
}: {
  handleRefreshToken: () => Promise<void>
}) => {
  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()
  const [resetUser] = useErrorHandlingMutation(
    useBackofficeUserManagementSwapGroupsMutation,
  )
  const handleResetGroups = async () => {
    const groupsList: string[] | undefined = store.get(GROUP_SWITCHER_FALLBACK)
    if (!groupsList) {
      enqueueMoiaErrorNotification('No user persisted yet try to reload')
      return
    }
    const response = await resetUser({
      variables: {
        input: {
          groupName: null,
          groupsList,
        },
      },
    })
    if (response.data?.backofficeUserManagementSwapGroups) {
      await handleRefreshToken()
    }
  }
  return (
    <ListItem
      sx={{
        '& button': {
          opacity: 0,
        },
        '&:hover': {
          '& button': {
            opacity: 1,
          },
        },
      }}
    >
      <ListItemText primary="Reset Groups" />
      <MoiaButton color="primary" onClick={handleResetGroups}>
        Reset
      </MoiaButton>
    </ListItem>
  )
}
