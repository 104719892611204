/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import {
  UseCurrentSortedAlertsTaskFragmentDoc,
  UseCurrentSortedAlertsAdAlertFragmentDoc,
} from '../../../../../alerts-common/src/lib/hooks/useCurrentSortedAlerts.hook'
import { UseAlertCountAdAlertFragmentDoc } from '../../../../../alerts-common/src/lib/utils/UseAlertCount.hook'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'
import {
  IsRebalancingFragmentDoc,
  HasHubReturnErrorFragmentDoc,
  HasHubReturnNextFragmentDoc,
  IsLowOnEnergyFragmentDoc,
  IsOverheatingFragmentDoc,
  IsSubCooledFragmentDoc,
} from '../../../../../vehicle-common/src/lib/utils/vehicleValidators.hook'
import {
  AlertLabelTaskFragmentDoc,
  AlertLabelAdAlertFragmentDoc,
} from './AlertLabel.hook'
import {
  VehicleDestinationMarkerPickupActionFragmentDoc,
  VehicleDestinationMarkerDeliveryActionFragmentDoc,
  VehicleDestinationMarkerHubReturnActionFragmentDoc,
  VehicleDestinationMarkerReallocationActionFragmentDoc,
} from './VehicleDestinationMarker.hook'

export type AdVehicleMarkerVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  inService: boolean | null
  label: number | null
  openedBy: Types.VehicleInteractionUserType | null
  engineType: Types.VehicleEngineType | null
  highVoltageBatteryLevel: number | null
  interiorTemperature: number | null
  latestLocation: {
    __typename?: 'VehicleLocation'
    heading: number | null
    location: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    } | null
  } | null
  fccUpcomingWaypoints: Array<{
    __typename?: 'FccWaypoint'
    actions: Array<
      | {
          __typename: 'FccWaypointDeliveryAction'
          estimatedAt: string
          seats: { __typename?: 'FccWaypointSeatInfo'; wheelchairs: number }
        }
      | {
          __typename: 'FccWaypointHubReturnAction'
          isPlannedHubReturn: boolean
          reason: Types.FccWaypointHubReturnReason
          estimatedAt: string
        }
      | {
          __typename: 'FccWaypointPickupAction'
          estimatedAt: string
          seats: { __typename?: 'FccWaypointSeatInfo'; wheelchairs: number }
        }
      | { __typename: 'FccWaypointReallocationAction'; estimatedAt: string }
    >
  }> | null
  taskNotifications: {
    __typename?: 'TaskNotificationList'
    tasks: Array<
      | {
          __typename?: 'HubServiceNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'IvcTaskNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'PassengerUnbuckled'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'RoadBlockageTaskNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'ShiftDeviationNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopAccessibilityDownvotedByDrivers'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopActivationFailedNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopAreaMissingActiveStopsNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopDownVotedByDrivers'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopPotentiallyUnreachable'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopValidationFailed'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'TimeManagementNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'TripPrebookingNoAssignment'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleAvailabilityNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleIncidentNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleSurveillanceNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleWaypointAttendance'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
    >
  } | null
  fccCurrentAdAlerts?: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename?: 'FccAdAlert'
      type: Types.FccAdAlertType
      status: Types.FccAdAlertStatus
      createdAt: string
      id: string
      assignee: { __typename?: 'BackofficeUser'; id: string } | null
    }>
  } | null
  nextWaypoint: {
    __typename?: 'Waypoint'
    visible: boolean
    waypointActions: Array<{
      __typename?: 'WaypointAction'
      kind: Types.WaypointActionKind
      reallocationRadius: number | null
    }>
    stop: {
      __typename?: 'Stop'
      routingLocation: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
    } | null
  } | null
  nextHubReturn: { __typename?: 'HubReturn'; type: Types.HubReturnType } | null
  currentlyLocatedInHub: { __typename?: 'Hub'; id: string } | null
  ignition: {
    __typename?: 'Ignition'
    ignitionState: Types.IgnitionState | null
    updatedAt: string | null
  } | null
}

export type AdVehicleMarkerPickupActionFragment = {
  __typename: 'FccWaypointPickupAction'
  estimatedAt: string
  seats: { __typename?: 'FccWaypointSeatInfo'; wheelchairs: number }
}

export type AdVehicleMarkerDeliveryActionFragment = {
  __typename: 'FccWaypointDeliveryAction'
  estimatedAt: string
  seats: { __typename?: 'FccWaypointSeatInfo'; wheelchairs: number }
}

export type AdVehicleMarkerHubReturnActionFragment = {
  __typename: 'FccWaypointHubReturnAction'
  isPlannedHubReturn: boolean
  reason: Types.FccWaypointHubReturnReason
  estimatedAt: string
}

export type AdVehicleMarkerReallocationActionFragment = {
  __typename: 'FccWaypointReallocationAction'
  estimatedAt: string
}

export const AdVehicleMarkerPickupActionFragmentDoc = gql`
  fragment AdVehicleMarkerPickupAction on FccWaypointPickupAction {
    __typename
    seats {
      wheelchairs
    }
    ...VehicleDestinationMarkerPickupAction
  }
  ${VehicleDestinationMarkerPickupActionFragmentDoc}
`
export const AdVehicleMarkerDeliveryActionFragmentDoc = gql`
  fragment AdVehicleMarkerDeliveryAction on FccWaypointDeliveryAction {
    __typename
    seats {
      wheelchairs
    }
    ...VehicleDestinationMarkerDeliveryAction
  }
  ${VehicleDestinationMarkerDeliveryActionFragmentDoc}
`
export const AdVehicleMarkerHubReturnActionFragmentDoc = gql`
  fragment AdVehicleMarkerHubReturnAction on FccWaypointHubReturnAction {
    __typename
    ...VehicleDestinationMarkerHubReturnAction
  }
  ${VehicleDestinationMarkerHubReturnActionFragmentDoc}
`
export const AdVehicleMarkerReallocationActionFragmentDoc = gql`
  fragment AdVehicleMarkerReallocationAction on FccWaypointReallocationAction {
    __typename
    ...VehicleDestinationMarkerReallocationAction
  }
  ${VehicleDestinationMarkerReallocationActionFragmentDoc}
`
export const AdVehicleMarkerVehicleFragmentDoc = gql`
  fragment AdVehicleMarkerVehicle on Vehicle {
    id
    inService
    label
    ...IsRebalancing
    ...HasHubReturnError
    ...HasHubReturnNext
    ...IsLowOnEnergy
    ...IsOverheating
    ...IsSubCooled
    latestLocation {
      heading
      location {
        latitude
        longitude
      }
    }
    fccUpcomingWaypoints {
      actions {
        ... on FccWaypointPickupAction {
          ...AdVehicleMarkerPickupAction
        }
        ... on FccWaypointDeliveryAction {
          ...AdVehicleMarkerDeliveryAction
        }
        ... on FccWaypointHubReturnAction {
          ...AdVehicleMarkerHubReturnAction
        }
        ... on FccWaypointReallocationAction {
          ...AdVehicleMarkerReallocationAction
        }
      }
    }
    taskNotifications(
      status: $taskNotificationStatus
      types: $taskNotificationTypes
    ) {
      tasks {
        createdAt
        status
        severity
        ...AlertLabelTask
        ...UseCurrentSortedAlertsTask
        ...AlertLabelTask
      }
    }
    fccCurrentAdAlerts @include(if: $withAdAlerts) {
      alerts {
        ...UseAlertCountAdAlert
        ...UseCurrentSortedAlertsAdAlert
        ...AlertLabelAdAlert
      }
    }
  }
  ${IsRebalancingFragmentDoc}
  ${HasHubReturnErrorFragmentDoc}
  ${HasHubReturnNextFragmentDoc}
  ${IsLowOnEnergyFragmentDoc}
  ${IsOverheatingFragmentDoc}
  ${IsSubCooledFragmentDoc}
  ${AdVehicleMarkerPickupActionFragmentDoc}
  ${AdVehicleMarkerDeliveryActionFragmentDoc}
  ${AdVehicleMarkerHubReturnActionFragmentDoc}
  ${AdVehicleMarkerReallocationActionFragmentDoc}
  ${AlertLabelTaskFragmentDoc}
  ${UseCurrentSortedAlertsTaskFragmentDoc}
  ${UseAlertCountAdAlertFragmentDoc}
  ${UseCurrentSortedAlertsAdAlertFragmentDoc}
  ${AlertLabelAdAlertFragmentDoc}
`
