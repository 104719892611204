/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type UpdateTaskNotificationNoteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
  note?: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type UpdateTaskNotificationNoteMutation = {
  __typename?: 'Mutation'
  updateTaskNotificationNote:
    | {
        __typename?: 'HubServiceNotification'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'IvcTaskNotification'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'PassengerUnbuckled'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'RoadBlockageTaskNotification'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'ShiftDeviationNotification'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'StopAccessibilityDownvotedByDrivers'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'StopActivationFailedNotification'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'StopAreaMissingActiveStopsNotification'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'StopDownVotedByDrivers'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'StopPotentiallyUnreachable'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'StopValidationFailed'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'TimeManagementNotification'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'TripPrebookingNoAssignment'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'VehicleAvailabilityNotification'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'VehicleIncidentNotification'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'VehicleSurveillanceNotification'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
    | {
        __typename?: 'VehicleWaypointAttendance'
        id: string
        note: {
          __typename?: 'TaskNotificationNote'
          note: string
          timestamp: string
        } | null
      }
}

export const UpdateTaskNotificationNoteDocument = gql`
  mutation updateTaskNotificationNote($id: ID!, $note: String) {
    updateTaskNotificationNote(id: $id, note: $note) {
      id
      note {
        note
        timestamp
      }
    }
  }
`
export type UpdateTaskNotificationNoteMutationFn = Apollo.MutationFunction<
  UpdateTaskNotificationNoteMutation,
  UpdateTaskNotificationNoteMutationVariables
>

/**
 * __useUpdateTaskNotificationNoteMutation__
 *
 * To run a mutation, you first call `useUpdateTaskNotificationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskNotificationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskNotificationNoteMutation, { data, loading, error }] = useUpdateTaskNotificationNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateTaskNotificationNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskNotificationNoteMutation,
    UpdateTaskNotificationNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTaskNotificationNoteMutation,
    UpdateTaskNotificationNoteMutationVariables
  >(UpdateTaskNotificationNoteDocument, options)
}
export type UpdateTaskNotificationNoteMutationHookResult = ReturnType<
  typeof useUpdateTaskNotificationNoteMutation
>
export type UpdateTaskNotificationNoteMutationResult =
  Apollo.MutationResult<UpdateTaskNotificationNoteMutation>
export type UpdateTaskNotificationNoteMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTaskNotificationNoteMutation,
    UpdateTaskNotificationNoteMutationVariables
  >
