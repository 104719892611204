/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeUpdateSetLastVisitMutationVariables = Types.Exact<{
  [key: string]: never
}>

export type BackofficeUpdateSetLastVisitMutation = {
  __typename?: 'Mutation'
  backofficeUpdateSetLastVisit: {
    __typename?: 'BackofficeUpdatesLastVisitResponse'
    lastVisitDate: string
  }
}

export type BackofficeStatusSetLastVisitMutationVariables = Types.Exact<{
  [key: string]: never
}>

export type BackofficeStatusSetLastVisitMutation = {
  __typename?: 'Mutation'
  backofficeStatusSetLastVisit: {
    __typename?: 'BackofficeStatusesLastVisitResponse'
    lastVisitDate: string
  }
}

export type AccountBadgeQueryVariables = Types.Exact<{ [key: string]: never }>

export type AccountBadgeQuery = {
  __typename?: 'Query'
  updateLastVisist: {
    __typename?: 'BackofficeUpdatesLastVisitResponse'
    lastVisitDate: string
  }
  statusLastVisit: {
    __typename?: 'BackofficeStatusesLastVisitResponse'
    lastVisitDate: string
  }
}

export const BackofficeUpdateSetLastVisitDocument = gql`
  mutation backofficeUpdateSetLastVisit {
    backofficeUpdateSetLastVisit {
      lastVisitDate
    }
  }
`
export type BackofficeUpdateSetLastVisitMutationFn = Apollo.MutationFunction<
  BackofficeUpdateSetLastVisitMutation,
  BackofficeUpdateSetLastVisitMutationVariables
>

/**
 * __useBackofficeUpdateSetLastVisitMutation__
 *
 * To run a mutation, you first call `useBackofficeUpdateSetLastVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUpdateSetLastVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeUpdateSetLastVisitMutation, { data, loading, error }] = useBackofficeUpdateSetLastVisitMutation({
 *   variables: {
 *   },
 * });
 */
export function useBackofficeUpdateSetLastVisitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeUpdateSetLastVisitMutation,
    BackofficeUpdateSetLastVisitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeUpdateSetLastVisitMutation,
    BackofficeUpdateSetLastVisitMutationVariables
  >(BackofficeUpdateSetLastVisitDocument, options)
}
export type BackofficeUpdateSetLastVisitMutationHookResult = ReturnType<
  typeof useBackofficeUpdateSetLastVisitMutation
>
export type BackofficeUpdateSetLastVisitMutationResult =
  Apollo.MutationResult<BackofficeUpdateSetLastVisitMutation>
export type BackofficeUpdateSetLastVisitMutationOptions =
  Apollo.BaseMutationOptions<
    BackofficeUpdateSetLastVisitMutation,
    BackofficeUpdateSetLastVisitMutationVariables
  >
export const BackofficeStatusSetLastVisitDocument = gql`
  mutation backofficeStatusSetLastVisit {
    backofficeStatusSetLastVisit {
      lastVisitDate
    }
  }
`
export type BackofficeStatusSetLastVisitMutationFn = Apollo.MutationFunction<
  BackofficeStatusSetLastVisitMutation,
  BackofficeStatusSetLastVisitMutationVariables
>

/**
 * __useBackofficeStatusSetLastVisitMutation__
 *
 * To run a mutation, you first call `useBackofficeStatusSetLastVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeStatusSetLastVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeStatusSetLastVisitMutation, { data, loading, error }] = useBackofficeStatusSetLastVisitMutation({
 *   variables: {
 *   },
 * });
 */
export function useBackofficeStatusSetLastVisitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeStatusSetLastVisitMutation,
    BackofficeStatusSetLastVisitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeStatusSetLastVisitMutation,
    BackofficeStatusSetLastVisitMutationVariables
  >(BackofficeStatusSetLastVisitDocument, options)
}
export type BackofficeStatusSetLastVisitMutationHookResult = ReturnType<
  typeof useBackofficeStatusSetLastVisitMutation
>
export type BackofficeStatusSetLastVisitMutationResult =
  Apollo.MutationResult<BackofficeStatusSetLastVisitMutation>
export type BackofficeStatusSetLastVisitMutationOptions =
  Apollo.BaseMutationOptions<
    BackofficeStatusSetLastVisitMutation,
    BackofficeStatusSetLastVisitMutationVariables
  >
export const AccountBadgeDocument = gql`
  query AccountBadge {
    updateLastVisist: backofficeUpdateGetLastVisit {
      lastVisitDate
    }
    statusLastVisit: backofficeStatusGetLastVisit {
      lastVisitDate
    }
  }
`

/**
 * __useAccountBadgeQuery__
 *
 * To run a query within a React component, call `useAccountBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountBadgeQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountBadgeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountBadgeQuery,
    AccountBadgeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AccountBadgeQuery, AccountBadgeQueryVariables>(
    AccountBadgeDocument,
    options,
  )
}
export function useAccountBadgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountBadgeQuery,
    AccountBadgeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AccountBadgeQuery, AccountBadgeQueryVariables>(
    AccountBadgeDocument,
    options,
  )
}
export function useAccountBadgeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AccountBadgeQuery,
        AccountBadgeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AccountBadgeQuery, AccountBadgeQueryVariables>(
    AccountBadgeDocument,
    options,
  )
}
export type AccountBadgeQueryHookResult = ReturnType<
  typeof useAccountBadgeQuery
>
export type AccountBadgeLazyQueryHookResult = ReturnType<
  typeof useAccountBadgeLazyQuery
>
export type AccountBadgeSuspenseQueryHookResult = ReturnType<
  typeof useAccountBadgeSuspenseQuery
>
export type AccountBadgeQueryResult = Apollo.QueryResult<
  AccountBadgeQuery,
  AccountBadgeQueryVariables
>
