import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Text, Heading, Callout } from '@moia-dev/pace-web'
import {
  currentEnvironmentNotBuildEnvironment,
  getEnvironment,
} from '@backoffice-frontend/environment'
import {
  getBackgroundByEnv,
  SwitchEnvironmentDropdown,
  Box,
} from '@backoffice-frontend/patterns'
import { LoginViewAreaId } from '../LoginViewAreaId'

export const PublicAppViewContentLayout = ({
  children,
  title,
  isMobile,
}: {
  children: React.ReactNode
  title: string
  isMobile: boolean
}) => {
  const { t } = useTranslation(LoginViewAreaId)
  const currentEnv = getEnvironment()
  const envMismatch = currentEnvironmentNotBuildEnvironment()

  const isBrowserSupported = React.useMemo(() => {
    const { userAgent } = navigator
    return userAgent.includes('Edg/') || userAgent.includes('Chrome/')
  }, [])

  return (
    <Card
      css={theme => ({
        padding: isMobile ? theme.space.Space2 : theme.space.Space6,
        width: isMobile ? '300px' : '400px',
        background: envMismatch
          ? getBackgroundByEnv(currentEnv, theme)
          : theme.semantic.ColorSurfaceDefault,
      })}
      outline={true}
      cornerRadius="large"
    >
      <Heading css={{ textAlign: 'center' }} size={isMobile ? 'md' : 'xl'}>
        {title}
      </Heading>
      {!isBrowserSupported && (
        <Callout
          variant={envMismatch ? 'critical' : 'warning'}
          title={t('Your Browser is not supported')}
        >
          {t(
            'Not everything might work as expected. We currently support Chrome and Edge',
          )}
        </Callout>
      )}
      {children}
      {envMismatch && (
        <Box paddingTop={2}>
          <Text size="lg">{t("You've changed the environment")}</Text>
          <SwitchEnvironmentDropdown />
        </Box>
      )}
    </Card>
  )
}
