import { QRCodeCanvas as QRCode } from 'qrcode.react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, LoadingIndicator, Text } from '@moia-dev/pace-web'
import { useAuthQrCode } from '@backoffice-frontend/authentication'
import { verifyTotpToken } from '@backoffice-frontend/common'
import { FormTextfield } from '@backoffice-frontend/forms'
import { Box, useMoiaErrorNotification } from '@backoffice-frontend/patterns'
import { usePaceValidators } from '@backoffice-frontend/validators'
import { LoginViewAreaId } from '../LoginViewAreaId'

type FormValues = {
  validationToken: string
}

export const MultiFactorRegistrationForm = ({
  onRedirect,
  isMobile,
}: {
  onRedirect: VoidFunction
  isMobile: boolean
}) => {
  const { t } = useTranslation(LoginViewAreaId)
  const { required } = usePaceValidators()
  const { code, qrCodeUrl } = useAuthQrCode()
  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()

  const { handleSubmit, control, formState } = useForm<FormValues>({
    defaultValues: { validationToken: '' },
    mode: 'onChange',
  })

  const onSubmit = async ({ validationToken }: FormValues) => {
    try {
      const res = await verifyTotpToken(validationToken)
      if (res === 'SUCCESS') {
        onRedirect()
      }
    } catch {
      enqueueMoiaErrorNotification(t('Code invalid. Please try again'))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ul
        css={theme => ({
          listStyle: 'numbered',
          paddingLeft: theme.spacing(3),
          marginTop: theme.space.Space6,
          marginBottom: theme.space.Space6,
        })}
      >
        <li>
          <Text color="default" size="md">
            {t('Open your authentication app (e.g. Okta)')}
          </Text>
        </li>
        <li>
          <Text>{t('Scan the QR Code using the authenticator app')}</Text>
          <Text>
            {qrCodeUrl && (
              <QRCode
                css={theme => ({
                  display: 'block',
                  marginBottom: theme.space.Space3,
                  marginTop: theme.space.Space3,
                })}
                size={100}
                value={qrCodeUrl}
              />
            )}
          </Text>
        </li>
        <li>
          <Text>
            {t(
              'You have problems with the scan? Please enter the following code manually',
            )}{' '}
            <span style={{ wordWrap: 'break-word' }}>{code}</span>
          </Text>
        </li>
        <li>
          <Text>{t('Enter the code generated by the app here')}</Text>
        </li>
      </ul>
      <FormTextfield
        control={control}
        name="validationToken"
        label={t('Code')}
        rules={{ required }}
        caption={t(
          'Please enter your 6-digit security code from your authentication app',
        )}
        size={isMobile ? 'compact' : 'default'}
      />
      <Box mt={3} display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          type="submit"
          disabled={!formState.isValid || formState.isSubmitting}
          variant="primary"
          label={t('Login')}
          size="compact"
          icon={formState.isSubmitting ? <LoadingIndicator /> : undefined}
        />
      </Box>
    </form>
  )
}
