/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type QueryEmployeeAutocompleteQueryVariables = Types.Exact<{
  employeesFilter?: Types.InputMaybe<Types.EmployeesFilterInput>
  pagination?: Types.InputMaybe<Types.PaginationInput>
}>

export type QueryEmployeeAutocompleteQuery = {
  __typename?: 'Query'
  employees: {
    __typename?: 'Employees'
    total: number
    employees: Array<{
      __typename?: 'Employee'
      email: string | null
      firstName: string | null
      id: string
      lastName: string | null
      mobile: string | null
      roles: Array<Types.Role>
      status: Types.EmployeeStatus
      confirmed: boolean
      externalId: string | null
      blocked: boolean
      wavQualification: boolean
    }>
  }
}

export const QueryEmployeeAutocompleteDocument = gql`
  query queryEmployeeAutocomplete(
    $employeesFilter: EmployeesFilterInput
    $pagination: PaginationInput
  ) {
    employees(employeesFilter: $employeesFilter, pagination: $pagination) {
      employees {
        email
        firstName
        id
        lastName
        mobile
        roles
        status
        confirmed
        externalId
        blocked
        wavQualification
      }
      total
    }
  }
`

/**
 * __useQueryEmployeeAutocompleteQuery__
 *
 * To run a query within a React component, call `useQueryEmployeeAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEmployeeAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEmployeeAutocompleteQuery({
 *   variables: {
 *      employeesFilter: // value for 'employeesFilter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useQueryEmployeeAutocompleteQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryEmployeeAutocompleteQuery,
    QueryEmployeeAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    QueryEmployeeAutocompleteQuery,
    QueryEmployeeAutocompleteQueryVariables
  >(QueryEmployeeAutocompleteDocument, options)
}
export function useQueryEmployeeAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryEmployeeAutocompleteQuery,
    QueryEmployeeAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    QueryEmployeeAutocompleteQuery,
    QueryEmployeeAutocompleteQueryVariables
  >(QueryEmployeeAutocompleteDocument, options)
}
export function useQueryEmployeeAutocompleteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QueryEmployeeAutocompleteQuery,
        QueryEmployeeAutocompleteQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    QueryEmployeeAutocompleteQuery,
    QueryEmployeeAutocompleteQueryVariables
  >(QueryEmployeeAutocompleteDocument, options)
}
export type QueryEmployeeAutocompleteQueryHookResult = ReturnType<
  typeof useQueryEmployeeAutocompleteQuery
>
export type QueryEmployeeAutocompleteLazyQueryHookResult = ReturnType<
  typeof useQueryEmployeeAutocompleteLazyQuery
>
export type QueryEmployeeAutocompleteSuspenseQueryHookResult = ReturnType<
  typeof useQueryEmployeeAutocompleteSuspenseQuery
>
export type QueryEmployeeAutocompleteQueryResult = Apollo.QueryResult<
  QueryEmployeeAutocompleteQuery,
  QueryEmployeeAutocompleteQueryVariables
>
