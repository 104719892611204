import { useTranslation } from 'react-i18next'
import { buildEnv, gitCommitHash } from '@backoffice-frontend/environment'
import {
  Grid,
  CardContent,
  Card,
  Body1,
  Caption,
} from '@backoffice-frontend/patterns'
import { AccountLayout } from './components/AccountLayout'

export const VersionView = () => {
  const { t } = useTranslation()
  return (
    <AccountLayout
      header={t('authenticatedAppView_version_header')}
      subheader={t('authenticatedAppView_version_caption')}
    >
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={2}>
              <Caption>{t('authenticatedAppView_environment_label')}</Caption>
              <Body1>{buildEnv()}</Body1>
            </Grid>
            <Grid item xs={4}>
              <Caption>{t('authenticatedAppView_gitCommitHash_label')}</Caption>
              <Body1 paragraph>{gitCommitHash}</Body1>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </AccountLayout>
  )
}
