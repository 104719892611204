import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EyeClosedIcon, EyeIcon } from '@moia-dev/pace-icons'
import { Button, LoadingIndicator } from '@moia-dev/pace-web'
import { Routes } from '@backoffice-frontend/common'
import { FormTextfield } from '@backoffice-frontend/forms'
import { MoiaGrid, MoiaLink } from '@backoffice-frontend/patterns'
import { usePaceValidators } from '@backoffice-frontend/validators'
import { LoginViewAreaId } from '../LoginViewAreaId'
import { useLoginType } from './useLoginType'

export const LoginForm = ({
  onSubmit,
  isMobile,
}: {
  onSubmit: (values: { username: string; password: string }) => Promise<void>
  isMobile: boolean
}) => {
  const { t } = useTranslation(LoginViewAreaId)
  const { disableCognitoLogin } = useLoginType()
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: '',
    },
  })
  const { required } = usePaceValidators()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MoiaGrid gridGap={isMobile ? 1 : 2} mt={isMobile ? 1 : 3}>
        <FormTextfield
          size={isMobile ? 'compact' : 'default'}
          control={control}
          autoComplete="email"
          name="username"
          rules={{ required }}
          label={t('E-Mail')}
          forceLowerCase
        />
        <FormTextfield
          size={isMobile ? 'compact' : 'default'}
          control={control}
          name="password"
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          rules={{ required }}
          label={t('Password')}
          trailing={
            showPassword ? (
              <EyeIcon onClick={() => setShowPassword(false)} />
            ) : (
              <EyeClosedIcon onClick={() => setShowPassword(true)} />
            )
          }
        />
      </MoiaGrid>
      <MoiaGrid
        mt={isMobile ? 1 : 3}
        gridGap={1}
        gridTemplateColumns="1fr min-content min-content"
      >
        <MoiaLink type="primary" to={Routes.Auth.ForgotPassword.url}>
          {t('Forgot Password?')}
        </MoiaLink>
        <Button
          variant="secondary"
          onClick={disableCognitoLogin}
          label={t('Back')}
          size="compact"
        />
        <Button
          type="submit"
          variant="primary"
          size="compact"
          disabled={formState.isSubmitting}
          label={t('Login')}
          icon={formState.isSubmitting ? <LoadingIndicator /> : undefined}
        />
      </MoiaGrid>
    </form>
  )
}
