import type { Control, FieldValues, UseFormSetValue } from 'react-hook-form'
import { useWatch } from 'react-hook-form'
import { Card, MiniTable, MiniTableRow, Text } from '@moia-dev/pace-web'
import { FormTextfield } from '@backoffice-frontend/forms'
import type { RioComponent } from '@backoffice-frontend/graphql'
import { RioComponentType } from '@backoffice-frontend/graphql'
import { MoiaGrid } from '@backoffice-frontend/patterns'
import {
  isIntegerOrEmpty,
  useIsMandatoryRuleActive,
  useRioTranslation,
} from '../../../index'
import type { UseComponentContext } from '../useComponentContext'

export type ReportFormFieldProps = {
  control: Control<FieldValues, string>
  rioComponent: RioComponent
  rules?: Record<string, unknown>
  setValue: UseFormSetValue<FieldValues>
  type?: string
  componentContext?: UseComponentContext
}

const READ_ONLY_COMPONENTS = [
  RioComponentType.VehicleVin,
  RioComponentType.VehicleType,
  RioComponentType.FleetName,
]

export const ReportReadOnlyComponent = ({
  rioComponent,
  componentValue,
}: {
  rioComponent: RioComponent
  componentValue: string
}) => {
  const tRio = useRioTranslation()
  return (
    <Card
      cornerRadius={'large'}
      padding={'small'}
      outline={true}
      key={rioComponent.componentId}
      css={theme => ({
        '& > div': { marginTop: theme.spacing(1) },
      })}
    >
      <MoiaGrid
        css={theme => ({
          margin: `${theme.space.Space2} ${theme.space.Space4}`,
        })}
        gridTemplateColumns="1fr 1fr"
      >
        <Text
          css={theme => ({
            color: theme.semantic.ColorContentReduced,
          })}
        >
          {tRio(rioComponent.label)}
        </Text>
        <div css={{ textAlign: 'right' }}>{componentValue}</div>
      </MoiaGrid>
    </Card>
  )
}

export const ReportFormField = ({
  control,
  rioComponent,
  rules,
  setValue,
  type = 'text',
  componentContext,
}: ReportFormFieldProps) => {
  const tRio = useRioTranslation()

  const componentValue = useWatch({
    control,
    name: rioComponent.componentId,
  })

  const required = useIsMandatoryRuleActive(rioComponent.componentId)

  const readOnly = READ_ONLY_COMPONENTS.includes(rioComponent.componentType)

  const isNextVisibleComponentReadOnly =
    componentContext?.nextVisualComponent &&
    READ_ONLY_COMPONENTS.includes(
      componentContext.nextVisualComponent.componentType,
    )
  const isPreviusVisibleComponentReadOnly =
    componentContext?.previousVisualComponent &&
    READ_ONLY_COMPONENTS.includes(
      componentContext.previousVisualComponent.componentType,
    )

  if (!readOnly) {
    return (
      <FormTextfield
        type={type}
        control={control}
        key={rioComponent.componentId}
        readOnly={readOnly}
        label={tRio(rioComponent.label)}
        name={rioComponent.componentId}
        size="compact"
        rules={{ required }}
        {...(rules?.type === 'number' && {
          onChange: e => {
            const value = e.target.value.trim()
            isIntegerOrEmpty(value)
              ? setValue(rioComponent.componentId, value)
              : e.preventDefault()
          },
        })}
      />
    )
  }

  if (!isPreviusVisibleComponentReadOnly && !isNextVisibleComponentReadOnly) {
    return (
      <ReportReadOnlyComponent
        rioComponent={rioComponent}
        componentValue={componentValue}
      />
    )
  }

  return (
    <MiniTable
      key={rioComponent.componentId}
      css={theme => ({
        '& > div': {
          ...(isNextVisibleComponentReadOnly && {
            marginBottom: theme.spacing(-1),
          }),
          ...(isPreviusVisibleComponentReadOnly && {
            marginTop: theme.spacing(-1),
          }),
        },
      })}
    >
      <MiniTableRow
        label={tRio(rioComponent.label)}
        content={componentValue}
        size={'compact'}
      />
    </MiniTable>
  )
}
