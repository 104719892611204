import { RioRuleDefinition } from '@backoffice-frontend/graphql'
import { useReportIoRulesContext } from './ReportIoRulesContextProvider'

export const useIsMandatoryRuleActive = (componentId: string) => {
  const rulesContext = useReportIoRulesContext()
  return rulesContext.rules.some(
    rule =>
      rule.componentId === componentId &&
      [
        RioRuleDefinition.MandatoryOnValue,
        RioRuleDefinition.Mandatory,
      ].includes(rule.rule) &&
      rule.active,
  )
}

export const useIsVisibleComponentByRule = (componentId: string) => {
  const rulesContext = useReportIoRulesContext()
  const visibleOnValueRule = rulesContext.rules.find(
    rule =>
      rule.componentId === componentId &&
      rule.rule === RioRuleDefinition.VisibleOnValue,
  )

  return visibleOnValueRule?.active ?? true
}
