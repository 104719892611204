import { useCallback, useEffect, useState } from 'react'
import type { FieldRenderProps } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { formatFileTypes } from '@backoffice-frontend/common'
import { useErrorHandlingMutation } from '@backoffice-frontend/data-fetching'
import type { UploadFileInterface } from '@backoffice-frontend/patterns'
import { FileUpload } from '@backoffice-frontend/patterns'
import {
  useBackofficeStatusDeleteMediaByFileNameMutation,
  useBackofficeUpdatesGetMediaPresignedUrlByFileNameMutation,
  useBackofficeUpdatesGetPresignedUrlMutation,
} from '@backoffice-frontend/updates-api'

type BackofficeUpdatesImagesProps = FieldRenderProps<
  Array<string>,
  HTMLDivElement
>

type ImageObject = UploadFileInterface & {
  originalUrl: string
}

export const BackofficeUpdatesImagesField = ({
  input: { value: imageUrls, onChange: handleValuesChange },
}: BackofficeUpdatesImagesProps) => {
  const { t } = useTranslation()
  const [imageObjects, setImageObjects] = useState<ImageObject[]>([])
  const [getPresignedUrl] = useErrorHandlingMutation(
    useBackofficeUpdatesGetPresignedUrlMutation,
  )
  const [getMediaPresignedUrl] = useErrorHandlingMutation(
    useBackofficeUpdatesGetMediaPresignedUrlByFileNameMutation,
  )
  const [deleteMediaByFileName] = useErrorHandlingMutation(
    useBackofficeStatusDeleteMediaByFileNameMutation,
  )

  const uploadFile = useCallback(
    async (file: File): Promise<string | undefined> => {
      const fileName = `${Date.now()}-${file.name}`
      const { data } = await getPresignedUrl({
        variables: {
          input: {
            fileName: fileName,
            contentType: file.type,
          },
        },
      })
      return fetch(data?.backofficeUpdatesGetPresignedUrl?.preSignedUrl ?? '', {
        body: file,
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
      }).then(() => fileName)
    },
    [getPresignedUrl],
  )

  const handleSetData = useCallback(
    async (files: File[]) => {
      const loadingFiles = files.map(file => ({
        file: file,
        url: '',
        originalUrl: '',
        loadingText: t('common_uploading'),
        loading: true,
      }))
      setImageObjects([...imageObjects, ...loadingFiles])

      const promises = files.map(async file => uploadFile(file))

      const newFiles = (await Promise.all(promises)).filter(Boolean)

      handleValuesChange([...imageUrls, ...newFiles])
    },
    [imageObjects, handleValuesChange, imageUrls, t, uploadFile],
  )

  useEffect(() => {
    async function fetchInitialMedia() {
      const promises = (Array.isArray(imageUrls) ? imageUrls : []).map(
        async url => {
          return getMediaPresignedUrl({
            variables: { input: { fileName: url } },
          })
            .then(({ data }) =>
              fetch(
                data?.backofficeUpdatesGetMediaPresignedUrlByFileName
                  ?.preSignedUrl ?? '',
              ),
            )
            .then(response => response.blob())
            .then(blob => ({
              file: new File([blob], url, { type: blob.type }),
              url: URL.createObjectURL(blob),
              originalUrl: url,
              loadingText: '',
              loading: false,
            }))
        },
      )
      return await Promise.all(promises)
    }

    fetchInitialMedia().then(values => {
      setImageObjects(values)
    })
    // getMediaPresignedUrl is not stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrls])

  const deleteFile = async (file: { url?: string; originalUrl: string }) => {
    if (!file.url) return

    await deleteMediaByFileName({
      variables: { input: { fileName: file.originalUrl } },
    })
    const filteredImages = imageObjects.filter(f => f.url !== file.url)

    URL.revokeObjectURL(file.url)
    handleValuesChange(filteredImages.map(it => it.originalUrl))
  }

  const allowedFileTypes = ['image/jpeg', 'image/png']

  const files = imageObjects.map(file => {
    return {
      file: {
        name: file.file.name,
        size: file.file.size,
        type: file.file.type,
      },
      url: file.url,
      loadingText: file.loadingText,
      loading: file.loading,
      originalUrl: file.originalUrl,
    }
  })

  const MAX_NUMBER_OF_FILES = 10

  return (
    <FileUpload
      files={files}
      maxNumberFiles={MAX_NUMBER_OF_FILES}
      onAddFiles={handleSetData}
      onDelete={deleteFile}
      allowedMimeTypes={allowedFileTypes}
      subtitle={t('updates_dialog_updateImages_label', {
        maxFiles: MAX_NUMBER_OF_FILES,
        countFiles: imageObjects.length,
        allowedFileTypes: formatFileTypes(allowedFileTypes),
      })}
    />
  )
}
