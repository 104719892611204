/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type PaginatedTripsByCustomerIdSortedByPickupQueryVariables =
  Types.Exact<{
    id: Types.Scalars['ID']['input']
    dateTimeInterval?: Types.InputMaybe<Types.DateTimeIntervalInput>
    first?: Types.InputMaybe<Types.Scalars['Int']['input']>
    after?: Types.InputMaybe<Types.Scalars['String']['input']>
  }>

export type PaginatedTripsByCustomerIdSortedByPickupQuery = {
  __typename?: 'Query'
  paginatedTripsByCustomerIdSortedByPickup: {
    __typename?: 'TripConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'TripEdge'
      cursor: string
      node: {
        __typename?: 'Trip'
        id: string
        state: Types.TripExecutionStatus
        tenant: any
        customerId: string
        orderTime: string | null
        createdAt: string
        updatedAt: string
        pickedUpAt: string | null
        earliestPickupTimeAtCreation: string | null
        deliveredAt: string | null
        fixedVehicleId: string | null
        serviceAreaId: string
        express: boolean
        drivingSystem: Types.TripDrivingSystem
        prebooked: boolean
        adults: number
        children: number
        schedule: {
          __typename?: 'TripSchedule'
          pickup: string
          delivery: string
          pickupPunctuality: Types.TripPunctuality
          deliveryPunctuality: Types.TripPunctuality
        } | null
        serviceArea: {
          __typename?: 'ServiceArea'
          displayNames: Partial<Record<'de' | 'en', string>>
        } | null
        origin: { __typename?: 'TripNamedLocation'; primaryAddress: string }
        destination: {
          __typename?: 'TripNamedLocation'
          primaryAddress: string
        }
        pickupStop: {
          __typename?: 'TripStop'
          stopId: string
          stop: {
            __typename?: 'Stop'
            names: Partial<Record<'de' | 'en', string>>
            address: {
              __typename?: 'StopAddress'
              city: string | null
              houseNumber: string | null
              postalCode: string | null
              street: string | null
            }
            routingLocation: {
              __typename?: 'Location'
              latitude: number
              longitude: number
            }
          } | null
        } | null
        deliveryStop: {
          __typename?: 'TripStop'
          stopId: string
          stop: {
            __typename?: 'Stop'
            names: Partial<Record<'de' | 'en', string>>
            address: {
              __typename?: 'StopAddress'
              city: string | null
              houseNumber: string | null
              postalCode: string | null
              street: string | null
            }
            routingLocation: {
              __typename?: 'Location'
              latitude: number
              longitude: number
            }
          } | null
        } | null
      }
    }>
  } | null
}

export const PaginatedTripsByCustomerIdSortedByPickupDocument = gql`
  query paginatedTripsByCustomerIdSortedByPickup(
    $id: ID!
    $dateTimeInterval: DateTimeIntervalInput
    $first: Int
    $after: String
  ) {
    paginatedTripsByCustomerIdSortedByPickup(
      id: $id
      dateTimeInterval: $dateTimeInterval
      first: $first
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          state
          tenant
          customerId
          orderTime
          createdAt
          updatedAt
          pickedUpAt
          earliestPickupTimeAtCreation
          deliveredAt
          fixedVehicleId
          schedule {
            pickup
            delivery
            pickupPunctuality
            deliveryPunctuality
          }
          serviceAreaId
          serviceArea {
            displayNames
          }
          express
          drivingSystem
          prebooked
          origin {
            primaryAddress
          }
          destination {
            primaryAddress
          }
          pickupStop {
            stop {
              address {
                city
                houseNumber
                postalCode
                street
              }
              routingLocation {
                latitude
                longitude
              }
              names
            }
            stopId
          }
          deliveryStop {
            stop {
              address {
                city
                houseNumber
                postalCode
                street
              }
              routingLocation {
                latitude
                longitude
              }
              names
            }
            stopId
          }
          adults
          children
        }
        cursor
      }
    }
  }
`

/**
 * __usePaginatedTripsByCustomerIdSortedByPickupQuery__
 *
 * To run a query within a React component, call `usePaginatedTripsByCustomerIdSortedByPickupQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedTripsByCustomerIdSortedByPickupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedTripsByCustomerIdSortedByPickupQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dateTimeInterval: // value for 'dateTimeInterval'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePaginatedTripsByCustomerIdSortedByPickupQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedTripsByCustomerIdSortedByPickupQuery,
    PaginatedTripsByCustomerIdSortedByPickupQueryVariables
  > &
    (
      | {
          variables: PaginatedTripsByCustomerIdSortedByPickupQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PaginatedTripsByCustomerIdSortedByPickupQuery,
    PaginatedTripsByCustomerIdSortedByPickupQueryVariables
  >(PaginatedTripsByCustomerIdSortedByPickupDocument, options)
}
export function usePaginatedTripsByCustomerIdSortedByPickupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedTripsByCustomerIdSortedByPickupQuery,
    PaginatedTripsByCustomerIdSortedByPickupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PaginatedTripsByCustomerIdSortedByPickupQuery,
    PaginatedTripsByCustomerIdSortedByPickupQueryVariables
  >(PaginatedTripsByCustomerIdSortedByPickupDocument, options)
}
export function usePaginatedTripsByCustomerIdSortedByPickupSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PaginatedTripsByCustomerIdSortedByPickupQuery,
        PaginatedTripsByCustomerIdSortedByPickupQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PaginatedTripsByCustomerIdSortedByPickupQuery,
    PaginatedTripsByCustomerIdSortedByPickupQueryVariables
  >(PaginatedTripsByCustomerIdSortedByPickupDocument, options)
}
export type PaginatedTripsByCustomerIdSortedByPickupQueryHookResult =
  ReturnType<typeof usePaginatedTripsByCustomerIdSortedByPickupQuery>
export type PaginatedTripsByCustomerIdSortedByPickupLazyQueryHookResult =
  ReturnType<typeof usePaginatedTripsByCustomerIdSortedByPickupLazyQuery>
export type PaginatedTripsByCustomerIdSortedByPickupSuspenseQueryHookResult =
  ReturnType<typeof usePaginatedTripsByCustomerIdSortedByPickupSuspenseQuery>
export type PaginatedTripsByCustomerIdSortedByPickupQueryResult =
  Apollo.QueryResult<
    PaginatedTripsByCustomerIdSortedByPickupQuery,
    PaginatedTripsByCustomerIdSortedByPickupQueryVariables
  >
