import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, LoadingIndicator } from '@moia-dev/pace-web'
import { DataTestIds, DialogType } from '@backoffice-frontend/common'
import { PatternsAreaId } from '../../PatternsAreaId'
import type { MoiaDialogProps } from './MoiaDialog'
import { MoiaDialog, MoiaDialogContent, MoiaDialogHeader } from './MoiaDialog'
import { MoiaDialogFormFooter } from './MoiaDialogForm'

type ConfirmDialogProps = {
  submitting?: boolean
  dialogText?: React.ReactNode
  dialogType?: DialogType
  disabledSubmitButton?: boolean
  enableEventPropagation?: boolean
  onConfirm?: () => void
  onCancel?: () => void
  onClose?: () => void
  cancelButtonText?: string
  confirmButtonText?: string
  // Overrides (@khudia- Totally frustrated by why TH I need this)
  header?: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
} & MoiaDialogProps

export const ConfirmDialog = ({
  submitting,
  cancelButtonText,
  confirmButtonText,
  dialogText,
  dialogType,
  disabledSubmitButton,
  enableEventPropagation,
  onConfirm,
  onCancel,
  onClick,
  onClose,
  header,
  ...props
}: ConfirmDialogProps) => {
  const { t } = useTranslation(PatternsAreaId)
  const handleConfirm = () => {
    onConfirm?.()
  }

  const handleCancel = () => {
    onCancel?.()
  }

  const handleClose = () => {
    if (onClose) {
      onClose()
    } else {
      onCancel?.()
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!enableEventPropagation) {
      e.stopPropagation()
    }

    onClick?.(e)
  }

  return (
    <MoiaDialog
      // NOTE (Stefanos): Below function is needed to prevent
      // the event from bubbling up to the interactive Table Row
      onClick={handleClick}
      onClose={handleClose}
      {...props}
    >
      {header && (
        <MoiaDialogHeader
          css={theme => ({ marginBottom: theme.spacing(2) })}
          title={header}
        />
      )}
      {dialogText && <MoiaDialogContent>{dialogText}</MoiaDialogContent>}
      <MoiaDialogFormFooter
        trailing={
          <>
            <Button
              key="cancel"
              onClick={handleCancel}
              data-testid={DataTestIds.DialogCancelButton}
              label={cancelButtonText ?? t('Cancel')}
              disabled={submitting}
              variant="secondary"
              size="compact"
            />

            <Button
              key="confirm"
              icon={submitting ? <LoadingIndicator /> : undefined}
              disabled={disabledSubmitButton || submitting}
              variant={
                dialogType === DialogType.DELETE
                  ? 'critical'
                  : dialogType &&
                      [DialogType.UPDATE, DialogType.CONFIRM].includes(
                        dialogType,
                      )
                    ? 'primary'
                    : undefined
              }
              onClick={handleConfirm}
              data-testid={DataTestIds.DialogConfirmButton}
              label={confirmButtonText ?? t('Confirm')}
              size="compact"
            />
          </>
        }
      />
    </MoiaDialog>
  )
}
