/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeUpdatesGetPresignedUrlMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.BackofficeUpdateFilePresignedUrlInput>
}>

export type BackofficeUpdatesGetPresignedUrlMutation = {
  __typename?: 'Mutation'
  backofficeUpdatesGetPresignedUrl: {
    __typename?: 'BackofficeUpdateFilePresignedResponse'
    preSignedUrl: string
    fileName: string
  }
}

export const BackofficeUpdatesGetPresignedUrlDocument = gql`
  mutation backofficeUpdatesGetPresignedUrl(
    $input: BackofficeUpdateFilePresignedUrlInput
  ) {
    backofficeUpdatesGetPresignedUrl(input: $input) {
      preSignedUrl
      fileName
    }
  }
`
export type BackofficeUpdatesGetPresignedUrlMutationFn =
  Apollo.MutationFunction<
    BackofficeUpdatesGetPresignedUrlMutation,
    BackofficeUpdatesGetPresignedUrlMutationVariables
  >

/**
 * __useBackofficeUpdatesGetPresignedUrlMutation__
 *
 * To run a mutation, you first call `useBackofficeUpdatesGetPresignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUpdatesGetPresignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeUpdatesGetPresignedUrlMutation, { data, loading, error }] = useBackofficeUpdatesGetPresignedUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBackofficeUpdatesGetPresignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeUpdatesGetPresignedUrlMutation,
    BackofficeUpdatesGetPresignedUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeUpdatesGetPresignedUrlMutation,
    BackofficeUpdatesGetPresignedUrlMutationVariables
  >(BackofficeUpdatesGetPresignedUrlDocument, options)
}
export type BackofficeUpdatesGetPresignedUrlMutationHookResult = ReturnType<
  typeof useBackofficeUpdatesGetPresignedUrlMutation
>
export type BackofficeUpdatesGetPresignedUrlMutationResult =
  Apollo.MutationResult<BackofficeUpdatesGetPresignedUrlMutation>
export type BackofficeUpdatesGetPresignedUrlMutationOptions =
  Apollo.BaseMutationOptions<
    BackofficeUpdatesGetPresignedUrlMutation,
    BackofficeUpdatesGetPresignedUrlMutationVariables
  >
