/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type UsePassengerAuthenticationsWaypointFragment = {
  __typename?: 'FccWaypoint'
  id: string
  actions: Array<
    | { __typename?: 'FccWaypointDeliveryAction' }
    | { __typename?: 'FccWaypointHubReturnAction' }
    | {
        __typename: 'FccWaypointPickupAction'
        id: string
        adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
      }
    | { __typename?: 'FccWaypointReallocationAction' }
  >
}

export type PassengerAuthenticationCardStateSubscriptionVariables =
  Types.Exact<{
    tripId: Types.Scalars['ID']['input']
  }>

export type PassengerAuthenticationCardStateSubscription = {
  __typename?: 'Subscription'
  fccPassengerAuthenticationState: {
    __typename?: 'FccPassengerAuthentication'
    tripId: string
    state: Types.FccPassengerCheckInStatus | null
    authenticationError: Types.FccPassengerAuthenticationError | null
  }
}

export const UsePassengerAuthenticationsWaypointFragmentDoc = gql`
  fragment UsePassengerAuthenticationsWaypoint on FccWaypoint {
    id
    actions {
      ... on FccWaypointPickupAction {
        __typename
        id
        adPassengerAuthenticationState
      }
    }
  }
`
export const PassengerAuthenticationCardStateDocument = gql`
  subscription passengerAuthenticationCardState($tripId: ID!) {
    fccPassengerAuthenticationState(tripId: $tripId) {
      tripId
      state
      authenticationError
    }
  }
`

/**
 * __usePassengerAuthenticationCardStateSubscription__
 *
 * To run a query within a React component, call `usePassengerAuthenticationCardStateSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePassengerAuthenticationCardStateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePassengerAuthenticationCardStateSubscription({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function usePassengerAuthenticationCardStateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    PassengerAuthenticationCardStateSubscription,
    PassengerAuthenticationCardStateSubscriptionVariables
  > &
    (
      | {
          variables: PassengerAuthenticationCardStateSubscriptionVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    PassengerAuthenticationCardStateSubscription,
    PassengerAuthenticationCardStateSubscriptionVariables
  >(PassengerAuthenticationCardStateDocument, options)
}
export type PassengerAuthenticationCardStateSubscriptionHookResult = ReturnType<
  typeof usePassengerAuthenticationCardStateSubscription
>
export type PassengerAuthenticationCardStateSubscriptionResult =
  Apollo.SubscriptionResult<PassengerAuthenticationCardStateSubscription>
