import { transformRoutes } from '../utils'

const baseRoute = '/report-io'

export const BaseReportIoAreaRoutes = {
  route: baseRoute,
  NewReport: {
    route: '/:category/:reportName/:serviceAreaUuid/new',
  },
  EditReport: {
    route: '/:category/:reportName/:reportId/edit',
    WithSection: {
      route: '#:section',
    },
  },
}

export const ReportIoAreaRoutes = transformRoutes(BaseReportIoAreaRoutes)
