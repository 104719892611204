/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

export type UseAlertTypeTranslationTask_HubServiceNotification_Fragment = {
  __typename?: 'HubServiceNotification'
  notificationType: Types.TaskNotificationType
}

export type UseAlertTypeTranslationTask_IvcTaskNotification_Fragment = {
  __typename?: 'IvcTaskNotification'
  notificationType: Types.TaskNotificationType
}

export type UseAlertTypeTranslationTask_PassengerAuthentificationFallbackRequestedNotification_Fragment =
  {
    __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
    notificationType: Types.TaskNotificationType
  }

export type UseAlertTypeTranslationTask_PassengerUnbuckled_Fragment = {
  __typename?: 'PassengerUnbuckled'
  notificationType: Types.TaskNotificationType
}

export type UseAlertTypeTranslationTask_RoadBlockageTaskNotification_Fragment =
  {
    __typename?: 'RoadBlockageTaskNotification'
    notificationType: Types.TaskNotificationType
  }

export type UseAlertTypeTranslationTask_ShiftDeviationNotification_Fragment = {
  __typename?: 'ShiftDeviationNotification'
  notificationType: Types.TaskNotificationType
}

export type UseAlertTypeTranslationTask_StopAccessibilityDownvotedByDrivers_Fragment =
  {
    __typename?: 'StopAccessibilityDownvotedByDrivers'
    notificationType: Types.TaskNotificationType
  }

export type UseAlertTypeTranslationTask_StopActivationFailedNotification_Fragment =
  {
    __typename?: 'StopActivationFailedNotification'
    notificationType: Types.TaskNotificationType
  }

export type UseAlertTypeTranslationTask_StopAreaMissingActiveStopsNotification_Fragment =
  {
    __typename?: 'StopAreaMissingActiveStopsNotification'
    notificationType: Types.TaskNotificationType
  }

export type UseAlertTypeTranslationTask_StopDownVotedByDrivers_Fragment = {
  __typename?: 'StopDownVotedByDrivers'
  notificationType: Types.TaskNotificationType
}

export type UseAlertTypeTranslationTask_StopPotentiallyUnreachable_Fragment = {
  __typename?: 'StopPotentiallyUnreachable'
  notificationType: Types.TaskNotificationType
}

export type UseAlertTypeTranslationTask_StopValidationFailed_Fragment = {
  __typename?: 'StopValidationFailed'
  notificationType: Types.TaskNotificationType
}

export type UseAlertTypeTranslationTask_TimeManagementNotification_Fragment = {
  __typename?: 'TimeManagementNotification'
  notificationType: Types.TaskNotificationType
}

export type UseAlertTypeTranslationTask_TripPrebookingNoAssignment_Fragment = {
  __typename?: 'TripPrebookingNoAssignment'
  notificationType: Types.TaskNotificationType
}

export type UseAlertTypeTranslationTask_VehicleAvailabilityNotification_Fragment =
  {
    __typename?: 'VehicleAvailabilityNotification'
    notificationType: Types.TaskNotificationType
  }

export type UseAlertTypeTranslationTask_VehicleIncidentNotification_Fragment = {
  __typename?: 'VehicleIncidentNotification'
  notificationType: Types.TaskNotificationType
}

export type UseAlertTypeTranslationTask_VehicleSurveillanceNotification_Fragment =
  {
    __typename?: 'VehicleSurveillanceNotification'
    notificationType: Types.TaskNotificationType
  }

export type UseAlertTypeTranslationTask_VehicleWaypointAttendance_Fragment = {
  __typename?: 'VehicleWaypointAttendance'
  notificationType: Types.TaskNotificationType
}

export type UseAlertTypeTranslationTaskFragment =
  | UseAlertTypeTranslationTask_HubServiceNotification_Fragment
  | UseAlertTypeTranslationTask_IvcTaskNotification_Fragment
  | UseAlertTypeTranslationTask_PassengerAuthentificationFallbackRequestedNotification_Fragment
  | UseAlertTypeTranslationTask_PassengerUnbuckled_Fragment
  | UseAlertTypeTranslationTask_RoadBlockageTaskNotification_Fragment
  | UseAlertTypeTranslationTask_ShiftDeviationNotification_Fragment
  | UseAlertTypeTranslationTask_StopAccessibilityDownvotedByDrivers_Fragment
  | UseAlertTypeTranslationTask_StopActivationFailedNotification_Fragment
  | UseAlertTypeTranslationTask_StopAreaMissingActiveStopsNotification_Fragment
  | UseAlertTypeTranslationTask_StopDownVotedByDrivers_Fragment
  | UseAlertTypeTranslationTask_StopPotentiallyUnreachable_Fragment
  | UseAlertTypeTranslationTask_StopValidationFailed_Fragment
  | UseAlertTypeTranslationTask_TimeManagementNotification_Fragment
  | UseAlertTypeTranslationTask_TripPrebookingNoAssignment_Fragment
  | UseAlertTypeTranslationTask_VehicleAvailabilityNotification_Fragment
  | UseAlertTypeTranslationTask_VehicleIncidentNotification_Fragment
  | UseAlertTypeTranslationTask_VehicleSurveillanceNotification_Fragment
  | UseAlertTypeTranslationTask_VehicleWaypointAttendance_Fragment

export type UseAlertTypeTranslationAdAlertFragment = {
  __typename?: 'FccAdAlert'
  type: Types.FccAdAlertType
}

export const UseAlertTypeTranslationTaskFragmentDoc = gql`
  fragment UseAlertTypeTranslationTask on TaskNotification {
    notificationType
  }
`
export const UseAlertTypeTranslationAdAlertFragmentDoc = gql`
  fragment UseAlertTypeTranslationAdAlert on FccAdAlert {
    type
  }
`
