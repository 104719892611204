import { useTranslation } from 'react-i18next'
import {
  useErrorHandlingMutation,
  useErrorHandlingQuery,
} from '@backoffice-frontend/data-fetching'
import { useToggle } from '@backoffice-frontend/hooks'
import {
  MoiaTableButton,
  useMoiaSuccessNotification,
} from '@backoffice-frontend/patterns'
import {
  BackofficeUpdatesByRightsDocument,
  useBackofficeUpdateByIdQuery,
  useBackofficeUpdateUpdateMutation,
} from '@backoffice-frontend/updates-api'
import { BackofficeUpdatesDialogContent } from './BackofficeUpdatesDialogContent'
import type { BackofficeUpdatesFormInput } from './BackofficeUpdatesTextForm'

type BackofficeUpdatesUpdateDialogProps = {
  id?: string
}

export const BackofficeUpdatesUpdateDialog = ({
  id,
}: BackofficeUpdatesUpdateDialogProps) => {
  const { t } = useTranslation()
  const [showDialogForm, toggleDialogForm] = useToggle()
  const skip = !id || !showDialogForm
  const { data, loading: dataLoading } = useErrorHandlingQuery(
    useBackofficeUpdateByIdQuery,
    {
      variables: { id: id ?? '' },
      skip,
    },
  )

  const update = data?.backofficeUpdateById

  const submitBackofficeUpdate = (input: BackofficeUpdatesFormInput) => {
    void backofficeUpdateUpdate({
      variables: {
        input: {
          id: id ?? '',
          creationDate: update?.creationDate ?? new Date().toISOString(),
          ...input,
        },
      },
      refetchQueries: [BackofficeUpdatesByRightsDocument],
    })
  }

  const { enqueueMoiaSuccessNotification } = useMoiaSuccessNotification()

  const [backofficeUpdateUpdate, { loading }] = useErrorHandlingMutation(
    useBackofficeUpdateUpdateMutation,
    {
      onCompleted: result => {
        if (result.backofficeUpdateUpdate) {
          // todo what if we have an error on BE side and do not return BO U object
          enqueueMoiaSuccessNotification(t('updates_toast_updateUpdateSuccess'))
        }
      },
    },
  )

  return (
    <div onClick={e => e.stopPropagation()}>
      {!dataLoading && (
        <BackofficeUpdatesDialogContent
          initialValues={{
            type: update?.type,
            rights: update?.rights ?? [],
            titleEn: update?.titleEn,
            titleDe: update?.titleDe,
            notesEn: update?.notesEn,
            notesDe: update?.notesDe,
            images: update?.images,
            videoEn: update?.videoEn,
            videoDe: update?.videoDe,
          }}
          onSubmit={submitBackofficeUpdate}
          header={t('updates_dialog_updateUpdate_header')}
          loading={loading || dataLoading}
          openDialog={showDialogForm}
          id={id}
          toggleOpenDialog={toggleDialogForm}
        />
      )}
      <MoiaTableButton
        className="revealOnHover"
        submitting={dataLoading}
        onClick={toggleDialogForm}
      >
        {t('common_edit_button')}
      </MoiaTableButton>
    </div>
  )
}
