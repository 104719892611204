import { useTranslation } from 'react-i18next'
import {
  Divider,
  H6,
  MoiaCard,
  MoiaCardContent,
  MoiaGrid,
} from '@backoffice-frontend/patterns'
import { AccountDivider, AccountLayout } from './components/AccountLayout'
import { DarkModeSwitch } from './components/DarkModeSwitch'
import { LanguageSwitch } from './components/LanguageSwitch'

export const PreferencesView = () => {
  const { t } = useTranslation()
  return (
    <>
      <AccountLayout
        header={t('authenticatedAppView_preferences_header')}
        subheader={t('authenticatedAppView_preferences_caption')}
      >
        <MoiaCard>
          <MoiaCardContent>
            <MoiaGrid
              column
              alignItems="baseline"
              gridTemplateColumns="60% 40%"
            >
              <H6>{t('authenticatedAppView_selectLanguage_label')}</H6>
              <LanguageSwitch />
            </MoiaGrid>
            <Divider spacing={2} />
            <MoiaGrid
              column
              alignItems="baseline"
              gridTemplateColumns="60% 40%"
            >
              <H6>{t('authenticatedAppView_selectAppearance_label')}</H6>
              <DarkModeSwitch />
            </MoiaGrid>
          </MoiaCardContent>
        </MoiaCard>
      </AccountLayout>
      <AccountDivider />
    </>
  )
}
