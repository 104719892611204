import { useTranslation } from 'react-i18next'
import { useErrorHandlingMutation } from '@backoffice-frontend/data-fetching'
import type { BackofficeStatusDelete } from '@backoffice-frontend/graphql'
import { useToggle } from '@backoffice-frontend/hooks'
import {
  Body2,
  MoiaButton,
  MoiaDialog,
  MoiaDialogContent,
  MoiaDialogFormFooter,
  useMoiaSuccessNotification,
} from '@backoffice-frontend/patterns'
import {
  BackofficeStatusesDocument,
  useBackofficeStatusDeleteMutation,
} from '@backoffice-frontend/status-api'

export type BackofficeStatusDeleteButtonProps = {
  onDelete: VoidFunction
} & BackofficeStatusDelete

export const BackofficeStatusDeleteButton = ({
  id,
  onDelete,
}: BackofficeStatusDeleteButtonProps) => {
  const { t } = useTranslation()
  const { enqueueMoiaSuccessNotification } = useMoiaSuccessNotification()

  const [confirmDialogOpen, toggleConfirmDialogOpen] = useToggle()

  const [backofficeStatusDelete, { loading }] = useErrorHandlingMutation(
    useBackofficeStatusDeleteMutation,
    {
      onCompleted: result => {
        if (result.backofficeStatusDelete.success) {
          enqueueMoiaSuccessNotification(t('status_toast_deleteStatusSuccess'))
          onDelete()
        }
      },
    },
  )

  const deleteBackofficeStatus = async () => {
    await backofficeStatusDelete({
      variables: {
        input: {
          id: id ?? '',
        },
      },
      refetchQueries: [BackofficeStatusesDocument],
    })
  }

  return (
    <>
      <MoiaDialog
        maxWidth="lg"
        open={confirmDialogOpen}
        onClose={toggleConfirmDialogOpen}
        header={t('status_dialog_deleteStatus_header')}
      >
        <MoiaDialogContent>
          <Body2 paragraph>{t('status_dialog_deleteStatus')}</Body2>
        </MoiaDialogContent>
        <MoiaDialogFormFooter
          trailing={
            <>
              <MoiaButton onClick={toggleConfirmDialogOpen}>
                {t('common_back_button')}
              </MoiaButton>
              <MoiaButton
                submitting={loading}
                onClick={deleteBackofficeStatus}
                color="primary"
              >
                {t('status_dialog_deleteStatus_button')}
              </MoiaButton>
            </>
          }
        />
      </MoiaDialog>
      <MoiaButton submitting={loading} onClick={toggleConfirmDialogOpen} danger>
        {t('common_delete_button')}
      </MoiaButton>
    </>
  )
}
