/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type UnassignAdAlertMutationVariables = Types.Exact<{
  alertId: Types.Scalars['ID']['input']
}>

export type UnassignAdAlertMutation = {
  __typename?: 'Mutation'
  unassignAlert: { __typename?: 'FccAdAlert'; id: string }
}

export const UnassignAdAlertDocument = gql`
  mutation unassignAdAlert($alertId: ID!) {
    unassignAlert(id: $alertId) {
      id
    }
  }
`
export type UnassignAdAlertMutationFn = Apollo.MutationFunction<
  UnassignAdAlertMutation,
  UnassignAdAlertMutationVariables
>

/**
 * __useUnassignAdAlertMutation__
 *
 * To run a mutation, you first call `useUnassignAdAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignAdAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignAdAlertMutation, { data, loading, error }] = useUnassignAdAlertMutation({
 *   variables: {
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useUnassignAdAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnassignAdAlertMutation,
    UnassignAdAlertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnassignAdAlertMutation,
    UnassignAdAlertMutationVariables
  >(UnassignAdAlertDocument, options)
}
export type UnassignAdAlertMutationHookResult = ReturnType<
  typeof useUnassignAdAlertMutation
>
export type UnassignAdAlertMutationResult =
  Apollo.MutationResult<UnassignAdAlertMutation>
export type UnassignAdAlertMutationOptions = Apollo.BaseMutationOptions<
  UnassignAdAlertMutation,
  UnassignAdAlertMutationVariables
>
