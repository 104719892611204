import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  MoiaEmptyState,
  MoiaTopBar,
  MoiaTopBarTitle,
  MoiaViewBox,
  TrackChangesIcon,
} from '@backoffice-frontend/patterns'
import { Restricted } from '@backoffice-frontend/restricted'
import { BackofficeStatusTable } from './BackofficeStatusTable'

export const BackofficeStatusView = () => {
  const { t } = useTranslation()

  return (
    <Restricted
      requiredRights={[BoumRight.BACKOFFICE_STATUS_CRUD]}
      replacement={<MoiaEmptyState title={t('status_header')} />}
    >
      <Helmet>
        <title>{t('status_header')}</title>
      </Helmet>
      <MoiaTopBar
        leading={
          <MoiaTopBarTitle
            icon={<TrackChangesIcon />}
            title={t('status_header')}
          />
        }
      />
      <MoiaViewBox>
        <BackofficeStatusTable />
      </MoiaViewBox>
    </Restricted>
  )
}
