import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Route, Routes as RouterRoutes } from 'react-router-dom'
import { Routes } from '@backoffice-frontend/common'
import {
  MoiaLogo,
  useMediaQuery,
  useTheme,
} from '@backoffice-frontend/patterns'
import { ForgotPasswordView } from './ForgotPasswordView'
import { LoginView } from './LoginView'
import { LoginViewAreaId } from './LoginViewAreaId'
import { PasswordChallengeView } from './PasswordChallengeView'
import { SetNewPasswordView } from './SetNewPasswordView'

export const PublicAppView = () => {
  const { t } = useTranslation(LoginViewAreaId)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Helmet>
        <title>{t('Welcome to the MOIA BackOffice')}</title>
      </Helmet>

      <div
        css={{
          minHeight: '100vh',
          backgroundSize: 'cover',
          alignItems: 'flex-start',
          justifyItems: 'center',
          backgroundImage: `url(/assets/images/login.png)`,
          display: 'grid',
        }}
      >
        <MoiaLogo
          css={{
            width: '160px',
            paddingTop: theme.spacing(6),
            fill: theme.palette.common.white,
          }}
        />
        <RouterRoutes>
          <Route path="/" element={<LoginView />} />
          <Route
            path={Routes.Auth.ForgotPassword.path}
            element={<ForgotPasswordView isMobile={isMobile} />}
          />
          <Route
            path={Routes.Auth.PasswordChallenge.path}
            element={<PasswordChallengeView isMobile={isMobile} />}
          />
          <Route
            path={Routes.Auth.SetNewPassword.path}
            element={<SetNewPasswordView isMobile={isMobile} />}
          />
        </RouterRoutes>
      </div>
    </>
  )
}
