import { useTranslation } from 'react-i18next'
import { useErrorHandlingMutation } from '@backoffice-frontend/data-fetching'
import { BackofficeUpdateType } from '@backoffice-frontend/graphql'
import { useToggle } from '@backoffice-frontend/hooks'
import {
  AddIcon,
  MoiaButton,
  useMoiaSuccessNotification,
} from '@backoffice-frontend/patterns'
import {
  BackofficeUpdatesByRightsDocument,
  useBackofficeUpdateCreateMutation,
} from '@backoffice-frontend/updates-api'
import { BackofficeUpdatesDialogContent } from './BackofficeUpdatesDialogContent'
import type { BackofficeUpdatesFormInput } from './BackofficeUpdatesTextForm'

export const BackofficeUpdatesCreateDialog = () => {
  const { t } = useTranslation()
  const [showDialogForm, toggleDialogForm] = useToggle()

  const submitBackofficeUpdate = (input: BackofficeUpdatesFormInput) => {
    void backofficeUpdateCreate({
      variables: {
        input,
      },
      refetchQueries: [BackofficeUpdatesByRightsDocument],
    })
  }

  const { enqueueMoiaSuccessNotification } = useMoiaSuccessNotification()

  const [backofficeUpdateCreate, { loading }] = useErrorHandlingMutation(
    useBackofficeUpdateCreateMutation,
    {
      onCompleted: () =>
        enqueueMoiaSuccessNotification(t('updates_toast_addUpdateSuccess')),
    },
  )

  return (
    <>
      <BackofficeUpdatesDialogContent
        initialValues={{
          type: BackofficeUpdateType.NewFeature,
          rights: [],
          titleEn: '',
          titleDe: '',
          notesEn: '',
          notesDe: '',
          videoEn: '',
          videoDe: '',
          images: [],
        }}
        onSubmit={submitBackofficeUpdate}
        header={t('updates_dialog_addUpdate_header')}
        loading={loading}
        openDialog={showDialogForm}
        toggleOpenDialog={toggleDialogForm}
      />
      <MoiaButton
        startIcon={<AddIcon />}
        color="primary"
        onClick={toggleDialogForm}
      >
        {t('updates_addUpdate_button')}
      </MoiaButton>
    </>
  )
}
