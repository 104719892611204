import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EyeClosedIcon, EyeIcon } from '@moia-dev/pace-icons'
import { Button, LoadingIndicator } from '@moia-dev/pace-web'
import { FormTextfield } from '@backoffice-frontend/forms'
import { MoiaGrid, useTheme } from '@backoffice-frontend/patterns'
import { usePaceValidators } from '@backoffice-frontend/validators'
import { LoginViewAreaId } from '../LoginViewAreaId'

export type SetNewPasswordValues = {
  verificationCode: string
  newPassword: string
  newPasswordRepeat: string
}

export const SetNewPasswordForm = ({
  onSubmit,
  isMobile,
}: {
  onSubmit: (values: SetNewPasswordValues) => Promise<void>
  isMobile: boolean
}) => {
  const { t } = useTranslation(LoginViewAreaId)
  const { required } = usePaceValidators()
  const { handleSubmit, control, formState, watch } =
    useForm<SetNewPasswordValues>({
      mode: 'onChange',
      defaultValues: {
        verificationCode: '',
        newPassword: '',
        newPasswordRepeat: '',
      },
    })

  const newPassword = watch('newPassword')
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)

  const theme = useTheme()

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginTop: isMobile ? theme.space.Space2 : theme.space.Space6 }}
    >
      <MoiaGrid mt={1} gridGap={isMobile ? 1 : 3}>
        <FormTextfield
          control={control}
          name="verificationCode"
          label={t('Verification Code')}
          rules={{ required }}
          autoComplete="code"
          removeSpaces
        />
        <FormTextfield
          control={control}
          name="newPassword"
          type={showPassword ? 'text' : 'password'}
          label={t('New password')}
          rules={{ required }}
          removeSpaces
          trailing={
            showPassword ? (
              <EyeIcon onClick={() => setShowPassword(false)} />
            ) : (
              <EyeClosedIcon onClick={() => setShowPassword(true)} />
            )
          }
        />
        <FormTextfield
          control={control}
          name="newPasswordRepeat"
          type={showPasswordRepeat ? 'text' : 'password'}
          label={t('Repeat password')}
          rules={{
            required,
            validate: value =>
              value === newPassword || t('Passwords do not match'),
          }}
          removeSpaces
          trailing={
            showPasswordRepeat ? (
              <EyeIcon onClick={() => setShowPasswordRepeat(false)} />
            ) : (
              <EyeClosedIcon onClick={() => setShowPasswordRepeat(true)} />
            )
          }
        />
        <MoiaGrid justifyContent="end" gridTemplateColumns="max-content" mt={1}>
          <Button
            type="submit"
            disabled={!formState.isValid || formState.isSubmitting}
            color="primary"
            label={t('Submit')}
            icon={formState.isSubmitting ? <LoadingIndicator /> : undefined}
          ></Button>
        </MoiaGrid>
      </MoiaGrid>
    </form>
  )
}
