import { useTranslation } from 'react-i18next'
import { Language, setLocale } from '@backoffice-frontend/common'
import { MoiaDropdown } from '@backoffice-frontend/patterns'

export const LanguageSwitch = ({ className }: { className?: string }) => {
  const { t, i18n } = useTranslation()
  return (
    <MoiaDropdown
      className={className}
      label={t('authenticatedAppView_language_label')}
      value={i18n.language}
      onChange={value => setLocale(value)}
      inputProps={{ name: 'laguage', id: 'language' }}
      options={[
        { value: Language.de, label: t('common_german_label') },
        { value: Language.en, label: t('common_english_label') },
      ]}
    />
  )
}
