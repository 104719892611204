/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type HubsQueryVariables = Types.Exact<{
  serviceAreaUUID?: Types.InputMaybe<Types.Scalars['ID']['input']>
}>

export type HubsQuery = {
  __typename?: 'Query'
  hubs: {
    __typename?: 'HubsResponse'
    hubs: Array<{
      __typename?: 'Hub'
      id: string
      name: string
      displayName: string
      serviceAreaUUID: string
    }>
  }
}

export const HubsDocument = gql`
  query hubs($serviceAreaUUID: ID) {
    hubs(serviceAreaUUID: $serviceAreaUUID) {
      hubs {
        id
        name
        displayName
        serviceAreaUUID
      }
    }
  }
`

/**
 * __useHubsQuery__
 *
 * To run a query within a React component, call `useHubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHubsQuery({
 *   variables: {
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *   },
 * });
 */
export function useHubsQuery(
  baseOptions?: Apollo.QueryHookOptions<HubsQuery, HubsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HubsQuery, HubsQueryVariables>(HubsDocument, options)
}
export function useHubsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HubsQuery, HubsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HubsQuery, HubsQueryVariables>(
    HubsDocument,
    options,
  )
}
export function useHubsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<HubsQuery, HubsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<HubsQuery, HubsQueryVariables>(
    HubsDocument,
    options,
  )
}
export type HubsQueryHookResult = ReturnType<typeof useHubsQuery>
export type HubsLazyQueryHookResult = ReturnType<typeof useHubsLazyQuery>
export type HubsSuspenseQueryHookResult = ReturnType<
  typeof useHubsSuspenseQuery
>
export type HubsQueryResult = Apollo.QueryResult<HubsQuery, HubsQueryVariables>
