/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type SetOperatorAvailabilityMutationVariables = Types.Exact<{
  availability: Types.FccOperatorAvailability
  serviceAreaUUID: Types.Scalars['ID']['input']
}>

export type SetOperatorAvailabilityMutation = {
  __typename?: 'Mutation'
  fccSetOperatorAvailability: {
    __typename?: 'FccOperatorState'
    availability: Types.FccOperatorAvailability
  }
}

export const SetOperatorAvailabilityDocument = gql`
  mutation setOperatorAvailability(
    $availability: FccOperatorAvailability!
    $serviceAreaUUID: ID!
  ) {
    fccSetOperatorAvailability(
      input: { availability: $availability, serviceAreaUUID: $serviceAreaUUID }
    ) {
      availability
    }
  }
`
export type SetOperatorAvailabilityMutationFn = Apollo.MutationFunction<
  SetOperatorAvailabilityMutation,
  SetOperatorAvailabilityMutationVariables
>

/**
 * __useSetOperatorAvailabilityMutation__
 *
 * To run a mutation, you first call `useSetOperatorAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOperatorAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOperatorAvailabilityMutation, { data, loading, error }] = useSetOperatorAvailabilityMutation({
 *   variables: {
 *      availability: // value for 'availability'
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *   },
 * });
 */
export function useSetOperatorAvailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetOperatorAvailabilityMutation,
    SetOperatorAvailabilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetOperatorAvailabilityMutation,
    SetOperatorAvailabilityMutationVariables
  >(SetOperatorAvailabilityDocument, options)
}
export type SetOperatorAvailabilityMutationHookResult = ReturnType<
  typeof useSetOperatorAvailabilityMutation
>
export type SetOperatorAvailabilityMutationResult =
  Apollo.MutationResult<SetOperatorAvailabilityMutation>
export type SetOperatorAvailabilityMutationOptions = Apollo.BaseMutationOptions<
  SetOperatorAvailabilityMutation,
  SetOperatorAvailabilityMutationVariables
>
