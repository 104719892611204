/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type UseRemainingRouteQueryVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
  withStops: Types.Scalars['Boolean']['input']
}>

export type UseRemainingRouteQuery = {
  __typename?: 'Query'
  vehicleByID?: {
    __typename?: 'Vehicle'
    id: string
    fccUpcomingWaypoints: Array<{
      __typename?: 'FccWaypoint'
      stop: {
        __typename?: 'Stop'
        routingLocation: {
          __typename?: 'Location'
          latitude: number
          longitude: number
        }
      } | null
    }> | null
  }
}

export const UseRemainingRouteDocument = gql`
  query useRemainingRoute($vehicleId: ID!, $withStops: Boolean!) {
    vehicleByID(id: $vehicleId) @include(if: $withStops) {
      id
      fccUpcomingWaypoints {
        stop {
          routingLocation {
            latitude
            longitude
          }
        }
      }
    }
  }
`

/**
 * __useUseRemainingRouteQuery__
 *
 * To run a query within a React component, call `useUseRemainingRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseRemainingRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseRemainingRouteQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      withStops: // value for 'withStops'
 *   },
 * });
 */
export function useUseRemainingRouteQuery(
  baseOptions: Apollo.QueryHookOptions<
    UseRemainingRouteQuery,
    UseRemainingRouteQueryVariables
  > &
    (
      | { variables: UseRemainingRouteQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UseRemainingRouteQuery,
    UseRemainingRouteQueryVariables
  >(UseRemainingRouteDocument, options)
}
export function useUseRemainingRouteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UseRemainingRouteQuery,
    UseRemainingRouteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UseRemainingRouteQuery,
    UseRemainingRouteQueryVariables
  >(UseRemainingRouteDocument, options)
}
export function useUseRemainingRouteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UseRemainingRouteQuery,
        UseRemainingRouteQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    UseRemainingRouteQuery,
    UseRemainingRouteQueryVariables
  >(UseRemainingRouteDocument, options)
}
export type UseRemainingRouteQueryHookResult = ReturnType<
  typeof useUseRemainingRouteQuery
>
export type UseRemainingRouteLazyQueryHookResult = ReturnType<
  typeof useUseRemainingRouteLazyQuery
>
export type UseRemainingRouteSuspenseQueryHookResult = ReturnType<
  typeof useUseRemainingRouteSuspenseQuery
>
export type UseRemainingRouteQueryResult = Apollo.QueryResult<
  UseRemainingRouteQuery,
  UseRemainingRouteQueryVariables
>
