import { ApolloClient, HttpLink, split, from } from '@apollo/client'
import type { TypePolicies } from '@apollo/client/cache'
import { InMemoryCache } from '@apollo/client/cache'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition, mergeDeep } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'
import {
  fetchWithAuthHeaders,
  getJwtString,
  GraphQLEndpoint,
  SubscriptionGatewayEndpoint,
} from '@backoffice-frontend/common'
import { apolloVersion } from '@backoffice-frontend/environment'
import { introspectionResult } from '@backoffice-frontend/graphql'
import { MoiaHeaderSearchTypePolicies } from '@backoffice-frontend/patterns'
import { MoiaServiceAreaTypePolicies } from '@backoffice-frontend/service-area-picker'

export const getApolloClient = (policies: TypePolicies[]) => {
  const TypePolicies = {
    ...mergeDeep(
      MoiaHeaderSearchTypePolicies,
      MoiaServiceAreaTypePolicies,
      ...policies,
    ),
    ShiftState: {
      keyFields: ['shiftId'],
    },
    EmployeeNote: {
      keyFields: ['noteId', 'employeeId'],
    },
    Vehicle: {
      keyFields: ['id'],
      fields: {
        latestLocation: {
          merge: true,
        },
        fccCurrentAdAlerts: {
          merge: false,
        },
        fccUpcomingWaypoints: {
          merge: false,
        },
      },
    },
  }

  const httpLink = new HttpLink({
    uri: GraphQLEndpoint,
    fetch: (url: URL | RequestInfo, options?: RequestInit) =>
      fetchWithAuthHeaders(url.toString(), {
        ...options,
        addTraceIdHeader: true,
      }),
  })

  const wsLink = new GraphQLWsLink(
    createClient({
      url: SubscriptionGatewayEndpoint,
      connectionParams: async () => ({
        authorization: await getJwtString(),
      }),
      retryAttempts: 10,
      lazy: true,
    }),
  )

  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: introspectionResult.possibleTypes,
      typePolicies: TypePolicies,
      // Note(Filip): the "resultCacheMaxSize" prop causes heavy performance regression in the Stop Network
    }),
    connectToDevTools: true,
    name: 'backoffice-frontend',
    version: apolloVersion,
    link: from([
      split(({ query }) => {
        const definition = getMainDefinition(query)

        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      }, wsLink),
      httpLink,
    ]),
  })
}
