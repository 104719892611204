import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BoumRight } from '@moia-dev/moia-token-claims'
import { createRoute, formatDateTime } from '@backoffice-frontend/common'
import { FeatureTogglesView } from '@backoffice-frontend/feature-toggle'
import {
  Caption,
  MoiaButton,
  MoiaTopBar,
  MoiaTopBarTitle,
  MoiaViewBox,
  PersonIcon,
} from '@backoffice-frontend/patterns'
import { Restricted, useUserHasAccess } from '@backoffice-frontend/restricted'
import { UserManagementAreaRoutes } from '@backoffice-frontend/user-management-api'
import {
  useBackofficeLoggedInUser,
  useLoggedInUserEmail,
} from '@backoffice-frontend/user-management-ui'
import { CredentialsView } from './CredentialsView'
import { GatewayMetadataSection } from './GatewayMetadata'
import { PermissionsView } from './PermissionsView'
import { PreferencesView } from './PreferencesView'
import { VersionView } from './VersionView'
import { AccountDivider, AccountLayout } from './components/AccountLayout'

export const AccountView = () => {
  const { t } = useTranslation()
  const { user } = useBackofficeLoggedInUser()
  const navigate = useNavigate()
  const hasHistoryRights = useUserHasAccess({
    requiredRights: [BoumRight.BACKOFFICE_USER_HISTORY_R],
  })
  const ownEmail = useLoggedInUserEmail() ?? ''
  const isOwnHistory = user?.email === ownEmail
  const canSeeHistory = isOwnHistory || hasHistoryRights

  return (
    <MoiaViewBox>
      <Helmet>
        <title>{t('authenticatedAppView_account_header')}</title>
      </Helmet>
      <MoiaTopBar
        leading={
          <MoiaTopBarTitle
            icon={<PersonIcon />}
            title={t('authenticatedAppView_account_header')}
          />
        }
      />
      <Restricted requiredRights={[BoumRight.DEV_USE_FEATURE_TOGGLES]}>
        <AccountLayout
          header={t('authenticatedAppView_features_header')}
          subheader={t('authenticatedAppView_features_caption')}
        >
          <FeatureTogglesView />
        </AccountLayout>
        <AccountDivider />
      </Restricted>
      <GatewayMetadataSection />
      <CredentialsView />

      <PermissionsView />

      <PreferencesView />

      <VersionView />
      {user && (
        <div
          css={theme => ({
            marginTop: theme.space.Space2,
            display: 'flex',
            alignItems: 'center',
            gap: theme.space.Space2,
          })}
        >
          <Caption>
            {t('common_createdAt_label')} {formatDateTime(user.createDate)}
            {' • '}
            {t('common_modifiedAt_label')}{' '}
            {formatDateTime(user.lastModifiedDate)}
          </Caption>
          {canSeeHistory && (
            <MoiaButton
              color="primary"
              variant="inline"
              onClick={() => {
                navigate(
                  createRoute(
                    UserManagementAreaRoutes.User.Show.History.route,
                    { id: user.id },
                  ),
                )
              }}
            >
              {t('authenticatedAppView_showHistory_button')}
            </MoiaButton>
          )}
        </div>
      )}
    </MoiaViewBox>
  )
}
