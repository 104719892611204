import { transformRoutes } from '../utils'

const baseRoute = '/data-dashboard'

export const BaseKpiDashboardAreaRoutes = {
  route: baseRoute,
  Overview: {
    route: '/overview',
  },
  DemandEstimation: {
    route: '/demand-estimation',
  },
  ScLabs: {
    route: '/sc-labs',
  },
  MobilityMetrics: {
    route: '/mobility-metrics',
  },
}

export const KpiDashboardAreaRoutes = transformRoutes(
  BaseKpiDashboardAreaRoutes,
)
