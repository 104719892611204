import DOMPurify from 'dompurify'
import { useTranslation } from 'react-i18next'
import snarkdown from 'snarkdown'
import { useErrorHandlingQuery } from '@backoffice-frontend/data-fetching'
import {
  Body2,
  MoiaDrawer,
  MoiaDrawerContent,
  MoiaDrawerHeader,
  MoiaDrawerLoadingContent,
  MoiaDrawerLoadingHeader,
  Overline,
  Subtitle1,
} from '@backoffice-frontend/patterns'
import { useBackofficeStatusByIdQuery } from '@backoffice-frontend/status-api'
import { TranslatedType } from './translateBackofficeStatus'

export const BackofficeStatusDrawer = ({
  onClose,
  id,
}: {
  onClose: VoidFunction
  id: string
}) => {
  const { data, loading } = useErrorHandlingQuery(
    useBackofficeStatusByIdQuery,
    { variables: { id }, skip: !id },
  )
  const { t } = useTranslation()

  const {
    type = undefined,
    title = '',
    notes = '',
  } = data?.backofficeStatusById ?? {}

  const markDownToHtml = snarkdown(notes)

  const sanitizedTranslatedNotesHtml = DOMPurify.sanitize(markDownToHtml, {
    ALLOWED_TAGS: ['strong', 'ol', 'ul', 'li', 's', 'br'],
  })

  return (
    <MoiaDrawer>
      {loading ? (
        <MoiaDrawerLoadingHeader />
      ) : (
        <MoiaDrawerHeader onClose={onClose}>
          <TranslatedType type={type} />
        </MoiaDrawerHeader>
      )}
      {loading ? (
        <MoiaDrawerLoadingContent />
      ) : (
        <MoiaDrawerContent>
          <Subtitle1 paragraph css={theme => ({ marginTop: theme.spacing(3) })}>
            {title}
          </Subtitle1>
          <Overline paragraph>{t('status_drawerHistory_header')}</Overline>
          <Body2 paragraph>
            <span
              dangerouslySetInnerHTML={{ __html: sanitizedTranslatedNotesHtml }}
            />
          </Body2>
        </MoiaDrawerContent>
      )}
    </MoiaDrawer>
  )
}
